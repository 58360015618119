import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ImageIcon from '@material-ui/icons/Image';

import { Card, GridFlex, AvatarIcon, EvaluationIcon, Slider, ButtonWithFill } from './styles';
import { ideaGeneralEvaluationActions } from '../../../actions';
import { MenuInformation } from '../../Shared';
import { DialogConcludeEvaluation } from './DialogConcludeEvaluation';
import { ReactComponent as ChecklistIcon } from '../../../assets/imgs/checklist-icon.svg';
import { ReactComponent as InventoryIcon } from '../../../assets/imgs/inventory-icon.svg';

const marks = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
];

export function IdeaEvaluation({ groupName, ideaId, groupId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialogConcludeEvaluate, setOpenDialogConcludeEvaluate] = useState(false);
  const { evaluations, comment, newEvaluations } = useSelector(state => state.ideaGeneralEvaluation);
  function handleOpenDialogConcludeEvaluation() {
    setOpenDialogConcludeEvaluate(!openDialogConcludeEvaluate);
  }

  function handleSetEvaluationComment(e) {
    const { value } = e.target;
    dispatch(ideaGeneralEvaluationActions.setEvaluationComment('comment', value));
  }

  function handleSetEvaluationRates(personalizedEvaluation, value) {
    dispatch(ideaGeneralEvaluationActions.setEvaluationRate(personalizedEvaluation, value));
  }

  function handleSubmit() {
    let data = {
      idea: ideaId,
      evaluationGroupId: groupId,
      comment: comment,
      evaluations: Object.keys(newEvaluations).map(id => ({ personalizedEvaluation: id, rate: newEvaluations[id] })),
    }
    dispatch(ideaGeneralEvaluationActions.createRatesAndComment(data, t('ideia_avaliada_com_sucesso'), ideaId));
  }

  return (<>
    <DialogConcludeEvaluation
      open={openDialogConcludeEvaluate}
      onClose={handleOpenDialogConcludeEvaluation}
      handleSubmit={handleSubmit}
    />
    <Card elevation={0}>
      <Grid container spacing={3}>
        <GridFlex item xs={12}>
          <AvatarIcon>
            <ChecklistIcon />
          </AvatarIcon>
          <Typography variant="h3">{groupName}</Typography>
        </GridFlex>
        <Grid item xs={12}>
          <Typography variant="body1">
            {t('avalie_a_ideia_com_base_nos_criterios_estabelecidos')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {evaluations?.map((item, index) => (
            <Box display="flex" alignItems="center" justifyContent="space-between" key={index}>
              <Box display="flex" alignItems="center">
                <EvaluationIcon 
                  src={item?.attachment?.url} 
                  alt="evaluation-icon" 
                  children={<ImageIcon fontSize="small"/>}
                />
                <Typography variant="subtitle1">{item.name}</Typography>
              </Box>
              <Box width="45%" display="flex" alignItems="center" justifyContent="space-between">
                <MenuInformation>
                  {item.description}
                </MenuInformation>
                <Slider
                  aria-labelledby="rate-evaluation"
                  valueLabelDisplay="auto"
                  name={`rate - ${index}`}
                  style={{ marginLeft: 16 }}
                  marks={marks}
                  max={5}
                  min={1}
                  defaultValue={1}
                  value={item.rate}
                  onChange={(_, value) => handleSetEvaluationRates(item._id, value)}
                />
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            variant="filled"
            name="comment"
            label={t('comentario')}
            minRows={4}
            onBlur={handleSetEvaluationComment}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <ButtonWithFill
              color="primary"
              variant="contained"
              startIcon={<InventoryIcon />}
              disabled={!comment}
              onClick={handleOpenDialogConcludeEvaluation}
            >
              {t('avaliar_ideia_geral')}
            </ButtonWithFill>
          </Box>
        </Grid>
      </Grid>
    </Card>
  </>);
}