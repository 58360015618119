import { avaliationDialogsConstants } from '../constants';

export const avaliationDialogsActions = {
  openAttachmentDialog,
  openImageDialog,
  openVideoDialog,
  openDocumentDialog,
  setImages,
  setFiles,
  setVideo,
  removeImage,
  removeVideo,
  removeFile,
  removeImageRender,
  removeVideoRender,
  removeFileRender,
  saveAttachments,
  clearDialogs
};

function openAttachmentDialog() {
  return dispatch => {
    dispatch({ type: avaliationDialogsConstants.HANDLE_OPEN_QUALIFICATION_ATTACHMENT_DIALOG })
  };
}

function openImageDialog() {
  return dispatch => dispatch({ type: avaliationDialogsConstants.HANDLE_OPEN_QUALIFICATION_IMAGE_DIALOG });
}

function openVideoDialog() {
  return dispatch => dispatch({ type: avaliationDialogsConstants.HANDLE_OPEN_QUALIFICATION_VIDEO_DIALOG });
}

function openDocumentDialog() {
  return dispatch => dispatch({ type: avaliationDialogsConstants.HANDLE_OPEN_QUALIFICATION_DOCUMENT_DIALOG });
}

function setImages(images) {
  return (dispatch) => {
    const payload = {
      images,
    };

    dispatch({ type: avaliationDialogsConstants.SET_STEP_FLUX_IMAGES, payload });
  };
}

function setVideo(video) {
  return (dispatch) => {
    const payload = {
      video,
    };
    dispatch({ type: avaliationDialogsConstants.SET_STEP_FLUX_VIDEO, payload });
  };
}

function setFiles(files) {
  return (dispatch) => {
    const payload = {
      files,
    };

    dispatch({ type: avaliationDialogsConstants.SET_STEP_FLUX_FILES, payload });
  }
}

function removeImage(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: avaliationDialogsConstants.REMOVE_STEP_FLUX_IMAGE, payload });
  };
}

function removeVideo() {
  return dispatch => dispatch({ type: avaliationDialogsConstants.REMOVE_STEP_FLUX_VIDEO });
}

function removeFile(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: avaliationDialogsConstants.REMOVE_STEP_FLUX_FILE, payload });
  };
}



function removeImageRender(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: avaliationDialogsConstants.REMOVE_STEP_FLUX_IMAGE_RENDER, payload });
  };
}

function removeVideoRender(id) {
  return dispatch => dispatch({ type: avaliationDialogsConstants.REMOVE_STEP_FLUX_VIDEO_RENDER, payload: { id } });
}

function removeFileRender(id) {
  return (dispatch) => {
    const payload = {
      id,
    };

    dispatch({ type: avaliationDialogsConstants.REMOVE_STEP_FLUX_FILE_RENDER, payload });
  };
}

function saveAttachments(renderName, dialogName) {
  return (dispatch) => {
    dispatch({ type: avaliationDialogsConstants.SAVE_STEP_FLUX_ATTACHMENTS, payload: { renderName, dialogName } });
  }
}

function clearDialogs() {
  return (dispatch) => {
    dispatch({ type: avaliationDialogsConstants.CLEAR_STEP_FLUX_DIALOGS });
  }
}
