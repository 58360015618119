import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  CardContent,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';
import { Description } from '@material-ui/icons';
import { articleActions } from '../../actions';
import { AvatarIconWithText } from '../Shared';
import { ArticleImageCard } from './ArticleImageCard';
import { Card } from './articlesStyles';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4, 0, 2),
  }
}));

export function ArticleInnovationCard() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { 
    newArticle: {
      innovationQuestions,
      attachmentQuestionTwo
    }
  } = useSelector((state) => state.articlesReducer);

  function handleSetFields(e, index) {
    const { value } = e.target;
    dispatch(articleActions.setFieldsAnswer({ index, answer: value }));
  }

  const SelectProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <>
      {innovationQuestions && innovationQuestions.length > 0 && (
        <Grid item xs={12}>
          <Card elevation={0}>
            <AvatarIconWithText
              icon={<Description color="primary" />}
              text={t('inovacao').toLocaleLowerCase()}
            />
            <CardContent className={classes.content}>
              <Grid container spacing={3}>
                {innovationQuestions.map((item, index) => (
                  <Grid item xs={12} key={item._id}>
                    {(!item?.isSelect && !item?.attachmentRequired) && (
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        variant="filled"
                        id={item._id}
                        name="answer"
                        label={item.question}
                        defaultValue={item?.answer}
                        onBlur={(e) => handleSetFields(e, index)}
                      />
                    )}

                    {(item?.isSelect && !item?.attachmentRequired) && (
                      <FormControl fullWidth variant="filled">
                        <InputLabel>{item.question}</InputLabel>
                          <Select
                            MenuProps={SelectProps}
                            defaultValue={item?.answer}
                            onChange={(e) => handleSetFields(e, index)}
                          >
                          {item?.valuesToSelect.map((value, index) => (
                            <MenuItem value={value} key={index}> {value}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {(!item?.isSelect && item?.attachmentRequired) && (
                      <Box>
                        <Typography variant="subtitle2">{item.question}</Typography>

                        <ArticleImageCard type="attachmentQuestionTwo" image={attachmentQuestionTwo} />
                      </Box>
                    )}
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
}
