import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Container } from '@material-ui/core';

import {
  BackNavAdmin,
  CustomSelect,
  SelectedUser,
  SidebarManageAccess,
  SkeletonUserProfile
} from '../../components';
import config from '../../config';
import { UserPoints } from '../UserPoints';
import { useWindowSize } from '../../hooks';
import { optionConstants } from '../../constants';
import { AdminUserEditPage } from '../AdminUserEditPage';
import { UserFunctionPage } from '../UserFunctionPage';
import { useAdminUserController } from './hooks/useAdminUserController';

export function AdminUserEditLayout() {
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const { userId, title, type, setType, loadingCollaborator } = useAdminUserController();

  return (
    <>
      <BackNavAdmin title={t(title)} />
      <Container maxWidth="lg" style={{ marginTop: 32 }}>
        <Grid container spacing={3}>
          {width < config.RESPONSIVE_MOBILE ? (
            <Grid item xs={12}>
              <CustomSelect
                optionsAvaiable={[
                  optionConstants.ATRIBUIR_FUNCAO_AO_USUARIO,
                  optionConstants.EDITAR_DADOS_DE_USUARIO,
                  optionConstants.GERENCIAR_PONTOS,
                ]}
                startOption={optionConstants.ATRIBUIR_FUNCAO_AO_USUARIO}
                handlePage={setType}
              />
            </Grid>
          ) : (
            <Grid item xs={3}>
              <SidebarManageAccess type={type} setType={setType} />
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {loadingCollaborator 
                  ? <SkeletonUserProfile />
                  : <SelectedUser showPoints={type === 'gerenciar-pontos' && true} />
                }
              </Grid>
              <Grid item xs={12}>
                {type === 'atribuir-funcao' && <UserFunctionPage userId={userId} />}
                {type === 'editar-dados' && <AdminUserEditPage userId={userId} />}
                {type === 'gerenciar-pontos' && <UserPoints userId={userId} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}