import React, { useState } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useSelector, useDispatch } from 'react-redux';
import { authenticationActions, feedbackActions } from '../../actions';
import {
  Button,
  Avatar,
  Menu,
  MenuItem,
  Paper,
  MenuList,
  makeStyles,
  Icon,
  Box,
  Hidden,
  useTheme,
  Typography,
  Chip
} from '@material-ui/core';
import { DialogFeedback } from '../Feedback';
import { AvatarWithoutPhoto } from '../Shared/AvatarWithoutPhoto';
import { Link } from 'react-router-dom';
import imagineIcon from '../../assets/imgs/imagine.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useTranslation } from 'react-i18next';
import { truncate } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  info: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  avatarButton: {
    marginLeft: theme.spacing(2),
  },
  config: {
    padding: theme.spacing(3, 4),
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  title: {
    fontWeight: 700,
  },
  logout: {
    padding: theme.spacing(1.5, 4),
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginRight: 8,
  },
  menuItemText: {
    color: theme.palette.neutrals.low.medium,
    padding: theme.spacing(1, 0),
  },
  menuIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.neutrals.low.medium,
  },
  perfil: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    borderRadius: 4,
    marginTop: 8
  }
}));

function Settings() {
  const user = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const { openFeedback } = useSelector((state) => state.feedback);
  const classes = useStyles();
  const dispatch = useDispatch(); 
  const { t } = useTranslation();
  const theme = useTheme();
  function handleCloseFeedback() {
    dispatch(feedbackActions.openFeedback(false));
  }

  function handleOpenFeedback() {
    handleClose();
    dispatch(feedbackActions.openFeedback(true));
  }

  function logout() {
    dispatch(authenticationActions.logout());
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function getFirstName(firstName) {
    const arrName = firstName.split(' ');
    if (arrName.length > 1) {
      return arrName[0];
    }
    return arrName;
  }

  return (
    <React.Fragment>
      <Button
        aria-controls="menu-profile"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
        endIcon={!anchorEl ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      >
        <Hidden smDown>
          <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
            {truncate(getFirstName(user.name))}
          </Typography>
        </Hidden>
        {user.attachment?.url ? (
          <Avatar
            alt={user.name}
            src={user.attachment.url}
            className={classes.avatarButton}
          />
        ) : (
          <AvatarWithoutPhoto userName={user.name} className={classes.avatarButton} />
        )}
      </Button>
      <Menu
        id="menu-profile"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        MenuListProps={{ disablePadding: true }}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
      >
        <Paper>
          <div className={classes.info}>
            {user.attachment && user.attachment.url ? (
              <Avatar
                src={user.attachment.url}
                alt={user.name}
                className={classes.avatar}
              />
            ) : (
              <AvatarWithoutPhoto xlarge userName={user.name} style={{ marginRight: 8 }} />
            )}
            {/* <Avatar
              alt={user.name}
              src={
                
              }
              style={{ marginRight: 8 }}
              className={classes.avatar}
            /> */}
            <div>
              <div style={{ fontSize: 20.25, fontWeight: 'bold' }}>
                {user.name}
              </div>
              <div style={{ fontSize: 14.22, fontWeight: 500 }}>
                {user.position}
                {user.businessUnitLocal?.occupationArea?.name && (
                  <Box fontWeight="fontWeightMedium" fontSize={14}>
                    {t('em')} {user.businessUnitLocal?.occupationArea?.name}
                  </Box>
                )}
              </div>
              {(user.typeProfile.type === 'manager' || user.typeProfile.type === 'admin') && (
                <Box>
                  <Chip label={user.typeProfile.description} color="primary" size="small" className={classes.perfil} />
                </Box>
              )}
            </div>
          </div>
          <div className={classes.config}>
            <Typography variant="subtitle2" className={classes.title}>{t('conta').toUpperCase()}</Typography>
            <MenuList>
              <MenuItem
                component={Link}
                to="/user/edit"
                onClick={handleClose}
                className={classes.menuItemText}
              >
                <PersonOutlineOutlinedIcon className={classes.menuIcon} />
                <Typography variant="subtitle2">{t('editar_perfil')}</Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                to="/user/settings"
                onClick={handleClose}
                className={classes.menuItemText}
              >
                <SettingsOutlinedIcon className={classes.menuIcon} />
                <Typography variant="subtitle2">{t('configuracoes_da_conta')}</Typography>
              </MenuItem>
            </MenuList>
            <Typography variant="subtitle2" className={classes.title}>IMAGINE</Typography>
            <MenuList style={{ padding: '8px 0 0' }}>
            {/* <div className={classes.title}>IMAGINE</div>
            <MenuList>
              <MenuItem className={classes.menuItemText}>
                <Icon className={classes.menuIcon}>
                  <img src={imagineIcon} alt="icon" />
                </Icon>
                sobre o Imagine
              </MenuItem>
              <MenuItem onClick={handleClose} className={classes.menuItemText}>
                <ContactSupportIcon className={classes.menuIcon} />
                central de ajuda
              </MenuItem>
              <MenuItem onClick={handleClose} className={classes.menuItemText}>
                <DescriptionIcon className={classes.menuIcon} />
                politícas e termos de privacidade
              </MenuItem>
            </MenuList> */}
              <MenuItem
               onClick={handleClose} 
               className={classes.menuItemText}
               component={Link}
               to="/help-center"
               >
                <ContactSupportOutlinedIcon className={classes.menuIcon} />
                <Typography variant="subtitle2">{t('central_de_ajuda')}</Typography>
              </MenuItem>
              <MenuItem 
                className={classes.menuItemText}
                onClick={handleOpenFeedback}
              >
                <FeedbackOutlinedIcon className={classes.menuIcon} />
                <Typography variant="subtitle2">{t('enviar_feedback')}</Typography>
              </MenuItem>
              <DialogFeedback 
                open={openFeedback}
                onClose={handleCloseFeedback}
              />
              <MenuItem onClick={handleClose} className={classes.menuItemText} component={Link} to="/regulation">
                <DescriptionOutlinedIcon className={classes.menuIcon} />
                <Typography variant="subtitle2">{t('politicas_e_termos_de_privacidade')}</Typography>
              </MenuItem> 
            </MenuList>
          </div>
          <Box className={classes.logout}>
            <MenuList>
              <MenuItem
                onClick={() => {
                  handleClose();
                  logout();
                }}
                className={classes.menuItemText}
              >
                <ExitToAppIcon
                  className={classes.menuIcon}
                  style={{ color: theme.palette.auxiliary.red.main }}
                />
                <Typography variant="subtitle2">{t('sair')}</Typography>
              </MenuItem>
            </MenuList>
          </Box>
        </Paper>
      </Menu>
    </React.Fragment>
  );
}

export { Settings };
