import './interceptors';

import React, { useEffect, useState } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router-dom';
import ScrollToTop from './components/Shared/ScrollTop';
import { SnackAlert, Outlet } from './components';
import { history } from './helpers';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga'; 
import config  from './config';

function App() {
  const alert = useSelector((state) => state.alert);
  const [openAlert, setOpenAlert] = useState(false);
  if (config.GOOGLE_ANALYTCS)
  {
    ReactGA.initialize(config.GOOGLE_ANALYTCS_ID);
    history.listen((location, action) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });
  }

  useEffect(() => {
    if (alert.message) {
      setOpenAlert(true);
    }
  }, [alert]);

  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Router history={history}>
        <ScrollToTop />
        {openAlert && (
          <SnackAlert
            message={alert.message}
            severity={alert.type}
            close={handleClose}
          />
        )}

        <Outlet />
      </Router>
    </React.Fragment>
  );
}

export default App;
