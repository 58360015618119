import React from 'react';
import { makeStyles } from '@material-ui/core';
import { MultiSelectCustom } from '../Shared';
import { useDispatch, useSelector } from 'react-redux';
import { manageUsersActions } from "./../../actions"
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  containerSelect: {
    display: 'flex',
    alignItems: 'center',
  },
  view: {
    color: theme.palette.neutrals.low.medium,
    marginRight: 10,
    fontSize: 12,
  },
}));

function UsersFilter() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters, paging } = useSelector(state => state.manageUsers);
  const { t } = useTranslation();

  function changeFilter(value) {
    dispatch(manageUsersActions.toggleFilter(value));
    dispatch(manageUsersActions.getFilteredUsers(
      paging.search,
      1,
      paging.sort,
      paging.limit,
      value
    ))
  }



  return (
    <div className={classes.root}>
      <div className={classes.containerSelect}>
        <div className={classes.view}>{t('visualizar')}:</div>
        {filters.length > 0 && (
          <MultiSelectCustom
            filters={filters.map(item => ({ id: item._id, value: item.type, name: item.description }))}
            statusCode={paging.statusCode}
            changeFilter={changeFilter}
          />
        )}
      </div>
    </div>
  );
}

export { UsersFilter };
