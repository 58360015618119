import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import DescriptionIcon from '@material-ui/icons/Description';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  DialogTitle,
  HeaderTitle,
  DialogContent,
  DialogActions
} from './styles';
import { AvatarIconWithText } from '../Shared';
import { ReactComponent as GroupIcon} from '../../assets/imgs/teamicon.svg';
import { evaluationTeamsActions } from '../../actions';

export function DialogCreateEvaluationTeam({ open, onClose, isEdit=false, team, openDialogDeleteTeam }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const { newTeam, responsibles } = useSelector(state => state.evaluationTeams);

  const filterOptions = createFilterOptions({
    limit: 100,
  });

  function handleSetEvaluationTeamName(e) {
    const { value } = e.target;
    dispatch(evaluationTeamsActions.setEvaluationTeamName('name', value));
  }

  function handleChangeTeamStatus(status) {
    dispatch(evaluationTeamsActions.changeTeamStatus(status));
  }

  function handleChangeParticipant(data, index) {
    if (data) {
      dispatch(evaluationTeamsActions.changeParticipantField(data.option, index));
    } else {
      dispatch(evaluationTeamsActions.clearParticipantField(index));
    }
  }

  function handleAddParticipantField() {
    dispatch(evaluationTeamsActions.addParticipantField());
  }

  function handleRemoveParticipant(value) {
    return () => {
      dispatch(evaluationTeamsActions.removeParticipantField(value));
    }
  }

  function handleCloseDialog() {
    onClose();
    dispatch(evaluationTeamsActions.clearDialogCreateTeam());
  }

  function handleOnSubmit() {
    let data = {
      name: newTeam.name,
      author: user._id,
      status: newTeam.status,
      participants: newTeam.participants.map(item => item._id)
    }

    if (isEdit) {
      dispatch(evaluationTeamsActions.updateEvaluationTeam(team._id, data, t('a_equipe_foi_salva')));
    } else {
      dispatch(evaluationTeamsActions.createEvaluationTeam(data, t('equipe_criada')));
    }
    
    handleCloseDialog();
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle disableTypography>
        <HeaderTitle>{isEdit ? t('editar_equipe') : t('nova_equipe')}</HeaderTitle>
        <IconButton aria-label="close" onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t('adicione_uma_nova_equipe_de_avaliacao_que_pode_atuar_em_fases_diferentes_do_desafio_ou_avaliando_um_conjunto_especifico_de_ideias')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AvatarIconWithText
              style={{ width: 32, height: 32 }}
              icon={<DescriptionIcon />}
              variant="subtitle1"
              text={t('detalhes_da_equipe')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label={t('nome_da_equipe')}
              variant="filled"
              defaultValue={newTeam.name}
              onBlur={handleSetEvaluationTeamName}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" style={{ marginRight: 8 }}>
                {t('status')}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  style={{ textTransform: 'capitalize' }}
                  label={newTeam.status === true ? t('ativa') : t('inativa')}
                  control={
                    <Switch
                      name="status"
                      color="primary"
                      checked={newTeam.status} 
                      onChange={() => handleChangeTeamStatus(!newTeam.status)}
                    />
                  }
                />
              </FormGroup>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <AvatarIconWithText
              style={{ width: 32, height: 32 }}
              icon={<GroupIcon />}
              variant="subtitle1"
              text={t('participantes')}
            />
          </Grid>
          {newTeam?.participants?.map((item, index) => (
            <Fragment key={`${index}-${item._id}`}>
              <Grid item xs={11}>
                <Autocomplete 
                  fullWidth
                  loadingText={t('carregando...')}
                  noOptionsText={t('sem_opcoes')}
                  options={responsibles}
                  filterOptions={filterOptions}
                  loading={responsibles?.length === 0}
                  getOptionLabel={(option) => option.name || ''}
                  getOptionSelected={(option, value) => option._id === value}
                  renderInput={(params) => <TextField {...params} label={t('participante')} variant="filled" />}
                  onChange={(_e, _items, _options, details) => handleChangeParticipant(details, index)}
                  defaultValue={{ name: item.name, _id: item._id }}
                />
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <IconButton onClick={handleRemoveParticipant(item._id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Fragment>
          ))}
          <Grid item xs={12}>
            <Button
              variant="text"
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={handleAddParticipantField}
            >
              {t('adicionar_participante')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent={isEdit ? 'space-between' : 'flex-end'}>
          {isEdit && (
            <Button
              variant="outlined"
              color="primary"
              onClick={openDialogDeleteTeam}
              startIcon={isEdit && <DeleteIcon />}
            >
              {t('excluir_equipe')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOnSubmit}
            startIcon={isEdit && <SaveIcon />}
          >
            {isEdit ? t('salvar_equipe') : t('criar_equipe')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}