import { userFunctionConstants } from "./../constants"
import { userFunctionService, userService } from "./../services"
import { history } from "./../helpers"
import { alertActions } from "./alert.actions";

export const userFunctionActions = {
  loadPage,
  getUser,
  setUser,
  setUserType,
  clearState,
  toggleDialogConfirmUser,
  toggleDialogCancelUser,
  toggleDialogConfirmEmail,
  submitUser,
  getAdminUserEdit,
  clearUserEditErrors,
  clearUserEditSuccess,
  updateUser,
  sendRequestNewPassword,
  clearAdminUserEdit,
  getAllUsers,
  getUserType,
  confirmPasswordRecover
}

function loadPage(userId, imagineLocals) {
  return (dispatch) => {
    dispatch({
      type: userFunctionConstants.USER_LOADING_REQUEST
    })

    userFunctionService.getUserType()
    .then((userType) => {
      dispatch({
        type: userFunctionConstants.GET_USER_TYPE_SUCCESS,
        payload: userType
      })
      
      if (userId) {
        userFunctionService.getUser(userId)
          .then((userInfo) => {
            dispatch({
              type: userFunctionConstants.GET_MANAGE_USER_SUCCESS,
              payload: {
                locals: imagineLocals,
                users: [userInfo.data.data],
                user: {
                  id: userInfo.data.data.id,
                  name: userInfo.data.data.name
                },
                profileType: userInfo.data.data.typeProfile.type,
                businessUnitLocals: userInfo.data.data.businessUnitLocals
              }
            })

            dispatch({
              type: userFunctionConstants.USER_LOADING_REQUEST_END
            })
            
          })
      } else {
        userFunctionService.getUsers(userType.data)
        .then((users) => {
          dispatch({
            type: userFunctionConstants.GET_MANAGE_USERS_SUCCESS,
            payload: {
              users: users.data
            }
          })
        })
      }
    })
    .catch((err) => {
      dispatch({
        type: userFunctionConstants.GET_USERS_FAILURE
      })
    })
  }
}

function getUser(userId) {
  return (dispatch) => {
    dispatch({ type: userFunctionConstants.GET_COLLABORATOR_BY_ID_REQUEST });
    userFunctionService.getUser(userId)
      .then(({ data }) => {
        dispatch({
          type: userFunctionConstants.GET_COLLABORATOR_BY_ID_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: userFunctionConstants.GET_COLLABORATOR_BY_ID_FAILURE,
          error
        });
      })
  }
}

function setUser(userId) { 
  return (dispatch) => {
    dispatch({
      type: userFunctionConstants.SET_USER,
      payload: userId
    })
  }
}

function setUserType(userTypeId) { 
  return (dispatch) => {
    dispatch({
      type: userFunctionConstants.SET_USER_TYPE,
      payload: userTypeId
    })
  }
}

function clearState() {
  return (dispatch) => {
    dispatch({
      type: userFunctionConstants.CLEAR_USER_FUNCTION_STATE,
    })
  }
}

function toggleDialogConfirmUser() {
  return (dispatch) => {
    dispatch({
      type: userFunctionConstants.TOGGLE_DIALOG_CONFIRM_USER
    })
  }
}

function toggleDialogCancelUser() {
  return (dispatch) => {
    dispatch({
      type: userFunctionConstants.TOGGLE_DIALOG_CANCEL_USER
    })
  }
}

function submitUser(data) {
  return (dispatch) => {
    dispatch({
      type: userFunctionConstants.SUBMIT_USER_REQUEST
    })

    userFunctionService.submitUser(data.id, data)
      .then((response) => {
        dispatch({
          type: userFunctionConstants.SUBMIT_USER_SUCCESS,
          payload: response.data
        })

        history.goBack();
      })
      .catch((err) => {
        dispatch({
          type: userFunctionConstants.SUBMIT_USER_FAILURE,
          payload: err
        })
      })
  }
}

function getAdminUserEdit(id) {
  return (dispatch) => {
    dispatch({ type: userFunctionConstants.GET_ADMIN_USER_EDIT_REQUEST });
    userService.getUserById(id)
      .then((response) => {
        dispatch({ 
          type: userFunctionConstants.GET_ADMIN_USER_EDIT_SUCCESS,
          payload: response
        })
      })
      .catch(() => {
        dispatch({ type: userFunctionConstants.CLEAR_ADMIN_USER_EDIT_ERRORS })
      })
  }
}

function clearUserEditErrors() {
  return dispatch => {
    dispatch({
      type: userFunctionConstants.CLEAR_ADMIN_USER_EDIT_ERRORS
    })
  }
}

function updateUser(user, userId, successMessage) {
  return (dispatch) => {
    dispatch({ type: userFunctionConstants.UPDATE_ADMIN_USER_EDIT_REQUEST });
    userService.updateUserAdmin(user)
      .then(() => {
        dispatch({ type: userFunctionConstants.UPDATE_ADMIN_USER_EDIT_SUCCESS });
        dispatch(alertActions.success(successMessage));
        dispatch(getAdminUserEdit(userId));
      })
      .catch((error) => {
        dispatch({ 
          type: userFunctionConstants.UPDATE_ADMIN_USER_EDIT_FAILURE,
          error
        })
      })
  };
}

function sendRequestNewPassword(email, successMessage, errorMessage) {
  return (dispatch) => {
    dispatch({ type: userFunctionConstants.REQUEST_EMAIL_NEW_PASSWORD_REQUEST });
    userService.sendRequestNewPasswor(email)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: userFunctionConstants.REQUEST_EMAIL_NEW_PASSWORD_SUCCESS });
          dispatch(alertActions.success(successMessage));
        } else {
          dispatch({ type: userFunctionConstants.REQUEST_EMAIL_NEW_PASSWORD_FAILURE });
          dispatch(alertActions.success(errorMessage));
        }
      })
      .catch((err) => {
        dispatch({ 
          type: userFunctionConstants.REQUEST_EMAIL_NEW_PASSWORD_FAILURE,
          error: err.toString()
        });
        dispatch(alertActions.success(errorMessage));
      })
  }
}

function toggleDialogConfirmEmail(value) {
  return dispatch => {
    dispatch({
      type: userFunctionConstants.TOGGLE_DIALOG_CONFIRM_EMAIL_RECOVER_PASSWORD,
      payload: value
    })
  }
}

function clearAdminUserEdit() {
  return dispatch => {
    dispatch({
      type: userFunctionConstants.CLEAR_ADMIN_USER_EDIT
    })
  }
}

function getAllUsers(userType) {
  return dispatch => {
    userFunctionService.getUsers(userType)
      .then((users) => {
        dispatch({
          type: userFunctionConstants.GET_MANAGE_USERS_SUCCESS,
          payload: {
            users: users.data
          }
        })
      })
    }

}

function clearUserEditSuccess() {
  return dispatch => {
    dispatch({
      type: userFunctionConstants.CLEAR_ADMIN_USER_EDIT_SUCCESS
    })
  }
}

function getUserType() {
  return dispatch => {
    userFunctionService.getUserType()
      .then((userType) => {
        dispatch({
          type: userFunctionConstants.GET_USER_TYPE_SUCCESS,
          payload: userType
        })
      })
  }
}

function confirmPasswordRecover(code, password, confirmPassword, successMessage) {
  return (dispatch) => {
    dispatch({ type: userFunctionConstants.SAVE_NEW_PASSWORD_REQUEST });
    userService.confirmPasswordRecover(code, password, confirmPassword)
      .then(() => {
        dispatch({ 
          type: userFunctionConstants.SAVE_NEW_PASSWORD_SUCCESS,
          payload: { code, password, confirmPassword }
        });
        dispatch(alertActions.success(successMessage));
      })
      .catch((error) => {
        dispatch({
          type: userFunctionConstants.SAVE_NEW_PASSWORD_FAILURE,
          error: error.toString()
        });
      })
  }
}