import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import { AvatarIconWithText, UserEdit } from '../../components';

export const AdminUserEditPage = () => {
  const { t } = useTranslation();
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AvatarIconWithText
          variant="h3"
          icon={<SettingsIcon />}
          text={t('editar_dados_do_usuario')}
        />
      </Grid>
      <Grid item xs={12}>
        <UserEdit loadAdminUserEdit={true} />
      </Grid>
    </Grid>
  );
}
