import { ideaCreateDialogsConstans } from '../constants';

export const ideaCreateDialogsActions = {
  openDialogTitleDescription,
  openDialogPhoto,
  openDialogArea,
  openDialogMultiArea,
  openDialogClassification,
  openDialogChallenge,
  openDialogSendIdea,
  openDialogVideo,
  openDialogFile,
  openDialogLink,
  openDialogCanvas,
  openDialogValueCanvas,
  openDialogClose,
  close,
  previousDialog,
};

function openDialogTitleDescription(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({ type: ideaCreateDialogsConstans.OPEN_DIALOG_TITLE_DESCRIPTION, payload });
  }
}

function openDialogPhoto(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({ type: ideaCreateDialogsConstans.OPEN_DIALOG_PHOTO, payload });
  };
}

function openDialogVideo(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({ type: ideaCreateDialogsConstans.OPEN_DIALOG_VIDEO, payload });
  };
}

function openDialogFile(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({ type: ideaCreateDialogsConstans.OPEN_DIALOG_FILE, payload });
  };
}

function openDialogLink(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({ type: ideaCreateDialogsConstans.OPEN_DIALOG_LINKS, payload });
  };
}

function openDialogArea(from) {
  return (dispatch) => {
    dispatch({ 
      type: from === "DIALOG_MULTI_AREA" ? ideaCreateDialogsConstans.OPEN_DIALOG_MULTI_AREA : ideaCreateDialogsConstans.OPEN_DIALOG_AREA,
      payload: from
    });
  };
}

function openDialogMultiArea(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({ type: ideaCreateDialogsConstans.OPEN_DIALOG_MULTI_AREA, payload });
  };
}

function openDialogClassification(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({
      type: ideaCreateDialogsConstans.OPEN_DIALOG_CLASSIFICATION, payload });
  };
}

function openDialogChallenge(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({
      type: ideaCreateDialogsConstans.OPEN_DIALOG_CHALLENGE,
      payload,
    });
  };
}

function openDialogSendIdea(from) {
  return (dispatch) =>
    dispatch({ 
      type: ideaCreateDialogsConstans.OPEN_DIALOG_SEND_IDEA, 
      payload: { 
        from 
      } 
    });
}

function openDialogCanvas(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({ type: ideaCreateDialogsConstans.OPEN_DIALOG_CANVAS, payload });
  };
}
function openDialogValueCanvas(from) {
  return (dispatch) => {
    const payload = {
      from,
    };
    dispatch({
      type: ideaCreateDialogsConstans.OPEN_DIALOG_VALUE_CANVAS,
      payload
    });
  };
}
function openDialogClose() {
  return (dispatch) => {
    dispatch({ type: ideaCreateDialogsConstans.OPEN_DIALOG_CLOSE });
  };
}

function close() {
  return (dispatch) => dispatch({ type: ideaCreateDialogsConstans.CLOSE_ALL });
}

function previousDialog() {
  return dispatch => {
    dispatch({ type: ideaCreateDialogsConstans.PREVIOUS_DIALOG_CREATE_IDEA })
  }
}