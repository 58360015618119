import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DialogAllUserFunctions, ListFunction, FormEditFunction, SkeletonUserFunction } from '../../components';

export const UserFunctionPage = () => {
  const [editFunctions, setEditFunctions] = useState(false);
  const { loadingCollaborator } = useSelector(state => state.userFunction);

  function handleToggleEdit() {
    setEditFunctions(!editFunctions)
  }

  return (
    <>
      <DialogAllUserFunctions toggleEdit={handleToggleEdit} />
        {editFunctions
          ? <FormEditFunction />
          : loadingCollaborator ? <SkeletonUserFunction /> : <ListFunction toggleEdit={handleToggleEdit} /> 
        }
    </>
  );
}