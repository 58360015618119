import { handleResponse, requestHeaders } from '../helpers';

import axios from 'axios';
import config from '../config';

let filters = {};
export const dashboardService = {
  getInicators,
  getTopAreas,
  getTopAreasGenerateIdeas,
  getTopUsers,
  getTopManagers,
  getIdeasStatus,
  getIdeasMonth,
  getIdeasList,
  getDashboardReportsCsv,
  setFilters,
  getFilters,
  getIndicatorsbySteps,
  getIdeasbySteps,
  getTopLocals,
  getTopLocalsGenerateIdeas,
  getIdeasByPeriod,
  getIdeasTotalByClassification,
  getIdeasFinancialReturnTotal
};

function getInicators(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/indicators`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopAreas(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/top-areas`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopLocals(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/top-locals`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopAreasGenerateIdeas(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/top-origin-areas`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopLocalsGenerateIdeas(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/top-origin-locals`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopUsers(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/top-users`, filter, requestHeaders())
    .then(handleResponse);
}

function getTopManagers(filter) {
  const endpoint = config.SHOW_IDEAS_BY_STEP_FLUX ? `${config.URL_BASE}/dashboard/top-managers-by-step` : `${config.URL_BASE}/dashboard/top-managers`
  return axios
    .post(endpoint, filter, requestHeaders())
    .then(handleResponse);
}

function getIdeasStatus(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/ideas-status`, filter, requestHeaders())
    .then(handleResponse);
}

function getIdeasMonth(filter) {
  const endpoint = config.SHOW_IDEAS_BY_STEP_FLUX ? `${config.URL_BASE}/dashboard/ideas-month-by-steps` : `${config.URL_BASE}/dashboard/ideas-month`;

  return axios
    .post(endpoint, filter, requestHeaders())
    .then(handleResponse);
}

function getIdeasList(filter) {
  const endpoint = config.SHOW_IDEAS_BY_STEP_FLUX ? `${config.URL_BASE}/dashboard/ideas-steps-list` : `${config.URL_BASE}/dashboard/ideas-list`

  return axios
    .post(endpoint, filter, requestHeaders())
    .then(handleResponse);
}

function getDashboardReportsCsv(data) {
  if (data.reportType !== 'top-users') delete data.sort;

  return axios.post(`${config.URL_BASE}/dashboard/${data.reportType}/csv`, data, requestHeaders());
}
function setFilters(f) {
  filters = f;
}
function getFilters() {
  return filters;
}

function getIdeasbySteps(filters) {
  return axios
    .post(`${config.URL_BASE}/dashboard/ideas-steps`, filters, requestHeaders())
    .then(handleResponse);
}

function getIndicatorsbySteps(filters) {
  return axios
    .post(`${config.URL_BASE}/dashboard/indicators`, filters, requestHeaders())
    .then(handleResponse);
}

function getIdeasByPeriod(periodType, filters) {
  if (periodType === "daily") {
    return axios
      .post(`${config.URL_BASE}/dashboard/ideas-by-period`, filters, requestHeaders())
      .then(handleResponse);
  } else {
    return axios
      .post(`${config.URL_BASE}/dashboard/ideas-accumulated-by-period`, filters, requestHeaders())
      .then(handleResponse);
  }
}

function getIdeasTotalByClassification(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/ideas-totals-by-personalized-classification`, filter, requestHeaders())
    .then(handleResponse);
}

function getIdeasFinancialReturnTotal(filter) {
  return axios
    .post(`${config.URL_BASE}/dashboard/ideas-financial-indicators`, filter, requestHeaders())
    .then(handleResponse);
}