import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../Routes';
import { Navbar } from '../Navbar';

export function Outlet() {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const authentication = useSelector((state) => state.authentication);

  return (
    <>
      {authentication?.loggedIn &&
       user &&
       user.isFullRegister && 
       history.location.pathname !== '/login' && <Navbar />}

      <Routes />
    </>
  );
}