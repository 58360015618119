import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  makeStyles,
  withStyles,
  InputAdornment,
  FormControl,
  IconButton,
  FormHelperText,
  FilledInput,
  Hidden,
  CircularProgress,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import MicrosoftLogin from 'react-microsoft-login';

import { authenticationActions } from '../../actions/authentication.actions';
import config from '../../config';
import loginImage from '../../assets/imgs/login.svg';
import logoImagine from '../../assets/imgs/logo.svg';
import logoOffice from '../../assets/imgs/microsoft-365.svg';
import clsx from 'clsx';
import { FooterAuthentication } from '../../components/Shared/FooterAuthentication';
import { deepOrange } from '@material-ui/core/colors';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import InfoIcon from '@material-ui/icons/Info';
import Autorenew from '@material-ui/icons/Autorenew';
// import GoogleBtn from './GoogleBtn';
import {
  GoogleLogin,
  GoogleLogout
} from 'react-google-login';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.high.lightest,
    flexGrow: 1,
    height: '100vh',
    padding: theme.spacing(0),
    margin: 0,
  },
  loginGrid: {
    backgroundColor: 'white',
  },
  loginContainer: {
    padding: theme.spacing(0, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
  },
  loginTitle: {
    fontSize: 28.83,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
  },
  subtitle: {
    fontSize: 14,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  spaceBottom: {
    paddingBottom: theme.spacing(3),
  },
  presentation: {
    padding: theme.spacing(0, 12),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerForm: {
    margin: theme.spacing(3, 0),
  },
  googleButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImagine: {
    width: '100%',
    maxWidth: 120,
    [theme.breakpoints.down('md')]: {
      maxWidth: 180,
      marginLeft: -8,
    },
  },
  containerLogoImagine: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  containerDivider: {
    position: 'relative',
    textAlign: 'center',
  },
  divider: {
    '&::after': {
      content: '" "',
      position: 'absolute',
      width: 'calc(50% - 24px)',
      height: 1,
      backgroundColor: theme.palette.neutrals.high.medium,
      top: 8,
      right: 0,
    },
    '&::before': {
      content: '" "',
      position: 'absolute',
      width: 'calc(50% - 24px)',
      height: 1,
      backgroundColor: theme.palette.neutrals.high.medium,
      top: 8,
      left: 0,
    },
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
  },
  containerImageBottom: {
    marginLeft: -15,
    marginRight: -15,
    marginBottom: theme.spacing(2),
  },
  footerContainer: {
    position: 'absolute',
    bottom: 10,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      marginTop: 20,
    },
  }, 
  fullWidth: {
    width: '100%'
  },
  descriptionSupport: {
    color: theme.palette.neutrals.low.light,
    textDecoration: 'none',
    textAlign:'center',
    transition: 'all ease-out .3s',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  infoIcon:{
    fontSize:'0.9em' 
  },
  loading:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: deepOrange[500],
    borderColor: deepOrange[500],
    width: '100%',
    fontWeight: 600,
  },
}))(Button);

function LogoutPage() {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  function onSubmit({ email, password }) {
    if (email && password) {
      dispatch(authenticationActions.login(email, password));
    }
  }
  function buttonLoading(){
    setLoading(!loading);
    dispatch(authenticationActions.logout365());
  }
  function authHandler(err, data) {
    setLoading(true); 
    loginOffice365(data.authResponseWithAccessToken.accessToken);
  }

  function loginOffice365(token) {
    dispatch(authenticationActions.loginOffice365(token));
  }

  function authHandlerGoogle(err, data) {
    loginGoogle(data.accessToken);
  }

  function loginGoogle(token) {
    dispatch(authenticationActions.loginGoogle(token.tokenId));
  }

  function logout() {
    dispatch(authenticationActions.logout365());
  }

  // useEffect(() => {
  //   dispatch(authenticationActions.logout365());
  // }, [dispatch]);

const clientId = `${config.GOOGLE_ID}`;
const gerror = response => {
  console.error(response) // eslint-disable-line
}

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container style={{ height: '100vh' }}>
        <Hidden smDown>
          <Grid item xs={8} md={6} lg={8} className={classes.presentation}>
            <div className={classes.containerLogoImagine}>
              <img
                className={classes.logoImagine}
                src={logoImagine}
                alt="logo-imagine"
              />
            </div>
            <h1 className={classes.title}>
              Transformando ideias em
              <br /> ciclos de experimentação
            </h1>
            <div>
              <img style={{ width: '100%' }} src={loginImage} alt="login"></img>
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6} lg={4} className={classes.loginGrid}>
          <div className={classes.loginContainer}>
            <Hidden mdUp>
              <div className={classes.containerLogoImagine}>
                <img
                  className={classes.logoImagine}
                  src={logoImagine}
                  alt="logo-imagine"
                />
              </div>
            </Hidden>
            
              <div className={clsx(classes.title, classes.loginTitle)}>
              

            <ColorButton
                  style={{ textTransform: 'lowercase' }}
                  variant="outlined"
                  onClick={buttonLoading}
                  disabled={loading}
                >

                {loading  ?(
                      <div className={classes.loading}>
                        <CircularProgress 
                          size={20}
                          style={{ marginRight: 10 }} 
                        />
                        carregando...
                      </div>
                  ) : (
                    <div className={classes.loading}>
                      <img
                        src={logoOffice}
                        alt="office 365"
                        style={{ marginRight: 10 }}
                      />
                      <span>Efetuar Logoff</span>
                    </div>
                  )}
                </ColorButton>
              </div> 
            <div className={classes.footerContainer}>
              <center>
              <a className={classes.descriptionSupport} href={"mailto:" + "suporte@imagineinovacao.com.br"}>Fale Conosco - Suporte</a>
              </center>
                <FooterAuthentication />
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export { LogoutPage };
