import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { executionActions } from '../../../actions';

export function useIdeaExecutionController() {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [openDialogJustification, setOpenDialogJustification] = useState(false);
  const {
    loading,
    estimatedStartDate,
    estimatedConclusionDate,
    executionDetails,
    estimatedFinancialReturn,
    costsFields,
  } = useSelector((state) => state.execution);

  useEffect(() => {
    dispatch(executionActions.loadArrayCosts())
  }, [dispatch])

  useEffect(() => {
    return function cleanup() {
      dispatch(executionActions.clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function openModelJustification() {
    setOpenDialogJustification(true);
  }

  function onCloseDialogJustification() {
    setOpenDialogJustification(false);
  }

  async function sendExecution() {
    const regexPattern = new RegExp(',', 'g');
    const regexPointPattern = new RegExp('[.]', 'g');

    let executionData = {
      estimatedStartDate,
      estimatedConclusionDate,
      executionDetails,
      estimatedFinancialReturn: estimatedFinancialReturn.replace(regexPointPattern, '').replace(regexPattern, '.'),
      costs: costsFields.map(item => {
        let estimatedCost = item.estimatedCost;
        estimatedCost = estimatedCost.replace(regexPointPattern, '').replace(regexPattern, '.');

        return {
          ...item,
          estimatedCost: estimatedCost
        }
      })
    }

    await dispatch(executionActions.executeIdea(executionData, id, t('ideia_em_execucao')));

    setDialogIsOpen(false);
  }

  function openDialog() {
    setDialogIsOpen(true);
  }

  function closeDialog() {
    setDialogIsOpen(false);
  }
  
  return {
    id,
    loading,
    dialogIsOpen,
    estimatedStartDate,
    estimatedConclusionDate,
    executionDetails,
    openModelJustification,
    openDialogJustification,
    onCloseDialogJustification,
    sendExecution,
    openDialog,
    closeDialog
  }
}