import { uniqueId } from 'lodash';
import { generalEvaluationConstants } from '../../constants';

const initialState = {
  group: [],
  groupDetails: {},
  evaluations: [],
  loading: false,
  paging: {
    page: 1,
    sort: { createdAt: -1 },
    limit: 10
  },
  criteriasToDelete: [],
  icons: [],
  loadingCriteria: false 
}

export function generalEvaluation(state = initialState, action) {
  switch (action.type) {
    case generalEvaluationConstants.SET_CRITERIA_ICON:
      return {
        ...state,
        icons: action.payload
      }

    case generalEvaluationConstants.CLEAR_CRITERIAS_TO_DELETE:
      return {
        ...state,
        criteriasToDelete: [],
        icons: []
      }

    case generalEvaluationConstants.EVALUATION_ADD_NEW_CRITERIA:
      const sortList = state.evaluations.sort((a, b) => a.order - b.order);
      const ultimateOrder = sortList[sortList.length - 1]?.order || 0;
      return {
        ...state,
        evaluations: [
          ...state.evaluations,
          {
            _id: uniqueId(),
            name: '',
            description: '',
            personalizedEvaluationGroup: '',
            order: ultimateOrder + 1,
            rateRange: '',
          }
        ]
      }

    case generalEvaluationConstants.REMOVE_EVALUATION_CRITERIA:
      return {
        ...state,
        criteriasToDelete: [...state.criteriasToDelete, action.payload.id],
        evaluations: [
          ...state.evaluations.filter(({ _id }, index) => _id !== action.payload.id),
        ]
      }

    case generalEvaluationConstants.SET_CRITERIA:
      return {
        ...state,
        evaluations: state.evaluations.map((item) => {
          if (item._id === action.payload.id) {
            return {
              ...item,
              [action.payload.field]: action.payload.value
            }
          }
          return item;
        })
      }

    case generalEvaluationConstants.CHANGE_FIELD_TITLE:
      return {
        ...state,
        group: state.group.map(item => {
          return {
            ...item,
            [action.payload.name]: action.payload.value
          }
        })
      }

    case generalEvaluationConstants.GET_EVALUATION_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      }
    case generalEvaluationConstants.GET_ALL_EVALUATIONS_BY_GROUP_REQUEST:
      return {
        ...state,
        loadingCriteria: true
      }

    case generalEvaluationConstants.GET_EVALUATION_GROUP_REQUEST:
    case generalEvaluationConstants.GET_EVALUATION_GROUP_BY_ID_REQUEST:
    case generalEvaluationConstants.CREATE_GENERAL_EVALUATION_REQUEST:
    case generalEvaluationConstants.UPDATE_GENERAL_EVALUATION_REQUEST:
    case generalEvaluationConstants.DELETE_GENERAL_EVALUATION_REQUEST:
    case generalEvaluationConstants.DELETE_GENERAL_EVALUATION_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      }

    case generalEvaluationConstants.GET_EVALUATION_GROUP_SUCCESS:
      return {
        ...state,
        group: action.payload,
        loading: false
      }

    case generalEvaluationConstants.GET_EVALUATION_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        groupDetails: action.payload,
        loading: false
      }

    case generalEvaluationConstants.GET_ALL_EVALUATIONS_BY_GROUP_SUCCESS:
      return {
        ...state,
        evaluations: action.payload,
        loadingCriteria: false
      }

    case generalEvaluationConstants.CREATE_GENERAL_EVALUATION_SUCCESS:
    case generalEvaluationConstants.UPDATE_GENERAL_EVALUATION_SUCCESS:
    case generalEvaluationConstants.DELETE_GENERAL_EVALUATION_SUCCESS:
    case generalEvaluationConstants.DELETE_GENERAL_EVALUATION_GROUP_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case generalEvaluationConstants.GET_ALL_EVALUATIONS_BY_GROUP_FAILURE:
      return {
        ...state,
        loadingCriteria: false
      }

    case generalEvaluationConstants.GET_EVALUATION_GROUP_FAILURE:
    case generalEvaluationConstants.GET_EVALUATION_GROUP_BY_ID_FAILURE:
    case generalEvaluationConstants.CREATE_GENERAL_EVALUATION_FAILURE:
    case generalEvaluationConstants.UPDATE_GENERAL_EVALUATION_FAILURE:
    case generalEvaluationConstants.DELETE_GENERAL_EVALUATION_FAILURE:
    case generalEvaluationConstants.DELETE_GENERAL_EVALUATION_GROUP_FAILURE:
      return {
        ...state,
        loading: false
      }

    case generalEvaluationConstants.DELETE_CRITERIA_IMAGE:
      return {
        ...state,
        evaluations: state.evaluations.map((item) => {
          if (item._id === action.payload.id) {
            return {
              ...item,
              attachment: {}
            }
          }
          return item;
        })
      }

    case generalEvaluationConstants.CHANGE_GENERAL_EVALUATION_TABLE_FILTERS: 
      return {
        ...state,
        paging: {
          ...state.paging,
          [action.payload.field]: action.payload.value
        }
      }
    

    case generalEvaluationConstants.CLEAR_GENERAL_EVALUATION:
      return initialState


    default:
      return state;
  }
}
