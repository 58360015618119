import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { 
  Box, 
  Grid, 
  makeStyles,
  Checkbox, 
  FormControl, 
  FormGroup, 
  FormControlLabel,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { formatDate } from '../../utils/date';
import { ButtonBaseMenu, Search } from '../Shared';
import { ReportFilters } from './ReportFilters';
import { reportActions } from '../../actions';
import { useLocation } from 'react-router-dom';
import TuneIcon from '@material-ui/icons/Tune';
import config from '../../config';

const useStyles = makeStyles((theme) => ({ 
  formControl: {
    padding: theme.spacing(2),
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
}));

export const ReportHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const [openFilter, setOpenFilter] = useState(null);
  const [openProperties, setOpenProperties] = useState(null);
  const [splittedPathname] = useState(history.location.pathname.split("/"));
  const { filters, paging, report, fieldValidation, tab } = useSelector(state => state.report);
  const { local, occupationArea, businessUnit } = useSelector(state => state.businessUnitProps);

  const statusOrStepLink = location.pathname === '/report/ideas-status' || location.pathname === '/report/ideas-by-step';
  const statusOrSteps = config.SHOW_IDEAS_BY_STEP_FLUX ? 'stepsIdeas' : 'statusCode';

  const handleClick = (e) => {
    setOpenFilter(e.currentTarget);
  };

  const handleClose = () => {
    setOpenFilter(null);
  };

  function handleChangeProperties(e) {
    const { checked, name } = e.target;
    const splittedPathname_index = name.split('-')[1];
    dispatch(reportActions.changeProperties(splittedPathname_index, checked));
  }

  function searchReport(query) {
    let params = {
      [statusOrSteps]: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.status && filters.status.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      startDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.startDate && formatDate(filters.startDate),
      endDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.endDate && formatDate(filters.endDate),
      reportType: splittedPathname[splittedPathname.length - 1],
      bulWithoutUsers: filters.bulWithoutUsers,
      ideasUngrouped: filters.ideasUngrouped,
      ideaDescriptionOnCsv: filters.ideaDescriptionOnCsv,
      useConclusionDate: filters.useConclusionDate,
      searchText: query
    }

    dispatch(reportActions.changeSearch(query))

    dispatch(reportActions.preFilter())

    if (tab === "table") {
      params = {
        ...params,
        page: 1,
        limit: paging.limit,
      }
      dispatch(reportActions.getReportData(Object.keys(requestParams).length !== 0 ? requestParams : params))

    } else {
      params = {
        ...params,
        page: -1,
      }
      dispatch(reportActions.getReportDataChart(Object.keys(requestParams).length !== 0 ? requestParams : params))
    }
  }

  useEffect(() => {
    return () => dispatch(reportActions.clearReportSearch())
  }, [dispatch])

  return (
    <Grid container spacing={3}>
      <Grid item>    
        <ButtonBaseMenu
          click={handleClick}
          open={Boolean(openFilter)}
          onClose={handleClose}
          anchorEl={openFilter}
          icon={<FilterListIcon />}
          titleButton={t('filtro')}
          titleMenu={t('filtrar_por')}
        >
          <ReportFilters closeFilter={handleClose} />
        </ButtonBaseMenu>
      </Grid>
          
      {statusOrStepLink && <><Grid item>
        {!!report.length && <ButtonBaseMenu
          click={(e) => setOpenProperties(e.currentTarget)}
          open={Boolean(openProperties)}
          onClose={() => setOpenProperties(null)}
          anchorEl={openProperties}
          icon={<TuneIcon />}
          titleButton={t('propriedades')}
          titleMenu={t('exibir')}
        >
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {filters.properties.map((item, index) => (
                <Box key={index}>
                  <FormControlLabel
                    label={item.label}
                    control={
                      <Checkbox
                        color="primary"
                        checked={item.checked}
                        name={`properties-${index}`}
                        onChange={handleChangeProperties}
                      />
                    }
                  />
                </Box>
              ))}
            </FormGroup>
          </FormControl>
        </ButtonBaseMenu>
}
      </Grid>

      <Grid item xs className={classes.search}>
        <Search
          query={searchReport}
          value={paging.query}
          placeholder={t('pesquise_por_nome_da_ideia_ou_status')}
        />
      </Grid></>}
    </Grid>
  );
}