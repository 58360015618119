import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, useTheme } from '@material-ui/core';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import FlagIcon from '@material-ui/icons/Flag';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { Person } from '@material-ui/icons';

import { SkeletonInfoDashboard } from '../../components/Skeletons';
import { Card, MiniCardsContainer, RowContainer, Label, Value } from './styles';

export function InfoDashboard({
  ideas,
  challenges,
  ups,
  comments,
  leadTime,
  leadTimeConclusion,
  indicatorsLoading,
  leadTimeProposers,
  roi
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const smallCardsData = [
    {
      label: t('ideias'),
      value: ideas,
      icon: <EmojiObjectsIcon style={{ color: theme.palette.primary.main }} />,
      color: theme.palette.primary.main
    },
    {
      label: t('desafios'),
      value: challenges,
      icon: <FlagIcon style={{ color: theme.palette.auxiliary.blue.main }} />,
      color: theme.palette.auxiliary.blue.main
    },
    {
      label: t('propositores'),
      value: leadTimeProposers || 0,
      icon: <Person style={{ color: theme.palette.auxiliary.green.main }} />,
      color: theme.palette.auxiliary.green.main
    },
    {
      label: t('up'),
      value: ups,
      icon: <ThumbUpAltIcon style={{ color: theme.palette.auxiliary.pink.main }} />,
      color: theme.palette.auxiliary.pink.main
    },
    {
      label: t('comentarios'),
      value: comments,
      icon: <ChatBubbleIcon style={{ color: theme.palette.auxiliary.orange.main }} />,
      color: theme.palette.auxiliary.orange.main
    }
  ];

  const bigCardsData = [
    {
      label: t('lead_time_avaliacao'),
      value: leadTime || 0,
      icon: <WatchLaterIcon style={{ color: theme.palette.auxiliary.cyan.main }} />,
      color: theme.palette.auxiliary.cyan.main,
    },
    {
      label: t('lead_time_implementaçao'),
      value: leadTimeConclusion || 0,
      icon: <WorkOutlineOutlinedIcon style={{ color: theme.palette.auxiliary.yellow.main }} />,
      color: theme.palette.auxiliary.yellow.main,
    },
    {
      label: 'ROI (%)',
      value: roi ?? 0,
      icon: <LocalAtmIcon style={{ color: theme.palette.neutrals.low.medium }} />,
      color: theme.palette.neutrals.low.medium,
    },
  ];

  return (
    <>
      {indicatorsLoading ? (
        <SkeletonInfoDashboard />
      ) : (
        <>
          <MiniCardsContainer> 
            {smallCardsData.map((card, index) => (
              <Card elevation={0} key={index}>
                {card.icon}
                <Value style={{ color: card.color, marginTop: 16 }}>
                  {card.value}
                </Value>
                <Label variant="subtitle2">
                  {card.label?.toLowerCase()}
                </Label>
              </Card>
            ))}
          </MiniCardsContainer>
          <Grid container spacing={2}>
            {bigCardsData.map((card, index) => (
              <Grid item xs={12} sm={4}>
                <Card elevation={0} key={index}>
                  <RowContainer>
                    {card.icon}
                    <Label variant="subtitle2">
                      {card.label}
                    </Label>
                  </RowContainer>
                  <Value style={{ color: card.color, marginTop: 24 }}>
                    {card.label === 'ROI (%)'
                      ? <>{card.value}</>
                      : <>{card.value} {card.value > 1 ? t('dias') : t('dia')}</>
                    }
                  </Value>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
}
