import React from 'react';
import { Container, Grid, makeStyles, Hidden } from '@material-ui/core';
import {
  SidebarUserEdit,
  UserSettings,
  BackNavAdmin,
  CustomSelect,
} from '../../components';
import { optionConstants } from '../../constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: { 
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
}));

export function SettingsPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  function handleChangePage(value) {
    if (value === optionConstants.PROFILE) {
      props.history.push(`/user/edit`);
    }
  }

  return (
    <>
      <BackNavAdmin title={t('meu_perfil')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Hidden smDown>
            <Grid item xs={3}>
              <SidebarUserEdit />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              <CustomSelect
                optionsAvaiable={[
                  optionConstants.PROFILE,
                  optionConstants.NOTIFICATIONS,
                ]}
                startOption={optionConstants.NOTIFICATIONS}
                handlePage={handleChangePage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9}>
            <UserSettings />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}