import { makeStyles } from '@material-ui/core';
import React from 'react'
import Linkify from 'react-linkify';

const useStyle = makeStyles(theme => ({
  linkWeb: {
    paddingTop: 0,
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    color: theme.palette.auxiliary.blue.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
}));

export const ImagineLinkify = ({ newTab = true, children }) => {

  const classes = useStyle();

  const componentNewTab = (href, text, key) => (
    <a style={{ wordWrap: 'break-word' }} className={classes.linkWeb} href={href} key={key} target="_blank" rel="noopener noreferrer" >
      {text}
    </a>
  );
  
  const component = (href, text, key) => (
    <a style={{ wordWrap: 'break-word' }} className={classes.linkWeb} href={href} key={key}>
      {text}
    </a>
  );

  return (
    <Linkify componentDecorator={newTab ? componentNewTab : component }>
      { children }
    </Linkify>
  )
}
