import {
  Checkbox,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  Select,
  makeStyles,
  useTheme,
} from '@material-ui/core';

import React from 'react';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    maxWidth: '100%',
    backgroundColor: theme.palette.neutrals.high.medium,
    borderRadius: 4,
    paddingRight: 10,
    paddingLeft: 10,
    marginRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  select: {
    minWidth: 0,
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

function MultiSelectCustom({ statusCode, filters, changeFilter }) {
  const classes = useStyles();
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const theme = useTheme();

  function handleChangeFilter(e) {
    changeFilter(e.target.value);
  }

  function handleStatusToName(status) {
    const filteredItem = filters.filter((item) => {
      return item.value === status;
    })
    return filteredItem[0].name;
  }

  return (
    <FormControl className={classes.formControl}>
      {width > config.RESPONSIVE_MOBILE ? (
        <span></span>
      ) : (
        <span
          className="material-icons"
          style={{ position: 'absolute', color: theme.palette.neutrals.low.medium }}
        >
          filter_alt
        </span>
      )}
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        fullWidth
        value={statusCode}
        onChange={handleChangeFilter}
        input={<Input />}
        renderValue={(selected) => {
          if (width > config.RESPONSIVE_MOBILE) {
            return `${handleStatusToName(selected[0])} ${
              selected.length > 1 ? `+${selected.length - 1}` : ''
              }`;
          }
        }}
        classes={{
          select: classes.select,
          icon: classes.icon,
        }}
        MenuProps={MenuProps}
        disableUnderline
      >
        {filters.map((filter, index) => (
          <MenuItem key={index} value={filter.value}>
            <Checkbox
              color="primary"
              checked={statusCode.indexOf(filter.value) > -1}
            />
            <ListItemText primary={filter.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { MultiSelectCustom };
