import React from 'react';
import { Box, Switch, FormControlLabel } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { articleActions } from '../../actions';
import { AvatarIconWithText } from '../Shared';
import { Card } from './articlesStyles';

function ArticleStatus({ isEdit = false }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { newArticle, articleDetails } = useSelector(
    (state) => state.articlesReducer
  );

  const status = isEdit ? articleDetails.status : newArticle.status;
  const highlight = isEdit ? articleDetails.highlight : newArticle.highlight;


  function handleSetStatus(status) {
    dispatch(articleActions.setStatus(status, isEdit));
  }

  function handleSetHighlight(highlight) {
    dispatch(articleActions.setHighlight(highlight, isEdit));
  }

  return (
    <Card elevation={0}>
      <AvatarIconWithText
        icon={<Settings color="primary" />}
        text={t('status')}
      />
      <Box paddingTop="2rem">
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={status}
              onChange={() =>
                handleSetStatus(!status)
              }
            />
          }
          label={status ? t('publicado') : t('nao_publicado')}
        />
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={highlight}
              onChange={() =>
                handleSetHighlight(!highlight)
              }
            />
          }
          label={highlight ? t('destacado') : t('nao_destacado')}
        />
      </Box>
    </Card>
  );
}

export { ArticleStatus };
