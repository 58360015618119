import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FooterTerms } from '../../components/Shared';
import accountSettings from '../../assets/imgs/account-settings.png';

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.primary.lightest ,
    color: theme.palette.neutrals.low.dark,
    fontWeight: 700,
    padding: theme.spacing(3.5, 0),
  },
  container: {
    paddingTop: theme.spacing(4),
    minHeight: '100vh',
  },
  underline: {
    textDecoration: 'underline'
  },
  imagineText: {
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  subtitle: {
    fontWeight: 700
  },
  link: {
    color: theme.palette.neutrals.low.dark,
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.primary.main,
    }
  }
}));

const sumary = [
  { label: '1. Definições Gerais'},
  { label: '2. Diretrizes e Definições'},
  { label: '3. Do Imagine'},
  { label: '4. Coleta de Dados'},
  { label: '5. Armazenamento de Dados'},
  { label: '6. Compartilhamento de Dados'},
  { label: '7. Direitos do Titular'},
  { label: '8. Atualizações'},
  { label: '9. Cookies'},
  { label: '10. Segurança'},
  { label: '11. Contato e Foro de Eleição/Legislação'},
];

export function RegulationPage() {
  const classes = useStyles();
  const theme = useTheme();
  
  return (
    <>
      <Box className={classes.header}>
        <Container maxWidth="lg">
          <Typography variant="h3">
            Políticas de Privacidade de Dados - Imagine
          </Typography>
        </Container>
      </Box>
      
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.subtitle}>Sumário</Typography>
            <List aria-label="Sumário">
              {sumary.map(({ label }, index) => (
                <ListItem disableGutters key={index} style={{ padding: '2px 0' }}>
                  <ListItemText primary={<Typography variant="body2">{label}</Typography>} />
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>1. Definições Gerais</Typography>
            <Typography variant="body2" paragraph>
              Permanentemente, empenhamos todas as nossas ações e os nossos esforços para prover segurança e proteção aos dados pessoais, tendo em vista ser uma das nossas prioridades.
            </Typography>
            <Typography variant="body2" paragraph>
              Neste sentido, todas as informações e dados que coletamos em nosso ambiente, são manuseados e armazenados de modo responsável e íntegro. Em total observância aos princípios das leis e regulamentações aplicáveis à privacidade e proteção de dados pessoais, com ênfase à Lei Geral de Proteção de Dados – LGPD – (Lei nº 13.709/2018) , bem como da GDPR (General Data Protection Regulation).
            </Typography>
            <Typography variant="body2" paragraph>
              A presente Política de Privacidade de Dados esclarece ao titular quais dados são coletados, como são coletados, qual o seu tratamento, como é a forma de armazenamento, se há qualquer compartilhamento com Terceiros, entre outras informações, visando o princípio da transparência, , de forma lícita e justa, conforme dispõe o art.5º, 1, (a) da GDPR.
            </Typography>
            <Typography variant="body2" paragraph>
              Ainda, ao concordar com esta política, o Usuário está em conformidade com os termos aqui expostos, bem como, adere aos códigos de conduta eventualmente publicados, em conformidade com o art.40º, 1 da GDPR.
            </Typography>
            <Typography variant="body2" paragraph>
              É importante ressaltar que a Plataforma <span className={classes.imagineText}>Imagine</span> tem medido os esforços necessários para proteger os dados por design e por padrão, considerando o estado da técnica e o custo da implementação. Logo, tem implementado as medidas técnicas e organizacionais adequadas, prezando pela minimização dos dados coletados.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>2. Diretrizes e Definições</Typography>
            <Typography variant="body2" paragraph>
              Em conformidade com a LGPD e com a GDPR, abaixo estão asdefinições de alguns dos termos utilizados nesse documento, obedecendo as definições estabelecidas em lei:
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Usuário:</strong> todas as pessoas físicas que utilizarão ou visitarão a Plataforma, maiores de 18 (dezoito) anos ou emancipadas e totalmente capazes de praticar os atos da vida civil. Ou ainda, os absolutamente/relativamente incapazes devidamente representados ou assistidos.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Dados Anonimizados:</strong> dado referente a uma pessoa que não pode ser identificada, considerando a utilização de meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.
            </Typography>
            <Typography variant="body2">
              <strong>Dados Pessoais:</strong> significa quaisquer informações fornecidas e/ou coletadas pela Plataforma <span className={classes.imagineText}>Imagine</span>, por qualquer meio, relativas a uma pessoa física identificada  ou relativas a uma pessoa física que, em combinação com outras informações, possa ser identificada.
            </Typography>
            <Typography variant="body2" paragraph>
              Obs: Os dados relativos a ferramentas de trabalho fornecidas pelo empregador, como telefone comercial, número de celular comercial, endereço comercial e e-mail comercial, não se enquadram como dados pessoais.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Finalidade:</strong> o propósito que a plataforma deseja alcançar, a partir de cada ato de tratamento das informações pessoais.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Necessidade:</strong> justificativa pelo qual é necessário coletar determinado dado pessoal, para atingir a finalidade, evitando-se a coleta excessiva.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Bases legais:</strong> fundamentação legal que torna legítimo o tratamento de dados pessoais, para uma determinada finalidade, por parte do <span className={classes.imagineText}>Imagine</span>.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Consentimento:</strong> uma manifestação de vontade, livre, específica e explícita, pela qual o titular dos dados aceita, de forma inequívoca, o uso de seus dados pessoais, para uma determinada finalidade. E nas hipóteses em que a legislação demande a autorização expressa do titular, conforme o art. 6º, 1, (a) e 4, (a) da GPDR. 
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>LGPD:</strong> Lei Geral de Proteção de dados, lei nº 13.709/18 que dispõe sobre o tratamento de dados pessoais, inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de direito público ou privado.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>GDPR:</strong> Lei estrangeira (provinda da Europa), conhecida também por RGPD, aplicável nos casos em que a Controladora transmita, colete ou receba dados da União Europeia. Essa lei estabelece regras relativas à proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e regras relativas à livre circulação de dados pessoais.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Controlador:</strong> pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais, qual seja, a Empresa contratante da plataforma <span className={classes.imagineText}>Imagine</span>. 
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Operador:</strong> pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador. Sendo, neste caso, a Empresa Contratante e a Contratada, sob as devidas segmentações aplicáveis.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Encarregado:</strong> pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD). Passível de acesso via lgpd@fcamara.com.br, Pessoa Jurídica de Direito Privado, detentora dos dados e da permissão para acessos, Fcamara Consultoria e Formação em Informática LTDA, CNPJ 03.775.758/0001-90.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Agentes de tratamento:</strong> o controlador e o operador de dados.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Tratamento:</strong> toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Eliminação:</strong> exclusão de dado ou de conjunto de dados armazenados, independentemente do procedimento empregado.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Empresa:</strong> empresa contratante da Plataforma <span className={classes.imagineText}>Imagine</span>, responsável por suas subdivisões internas.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Transferência internacional de dados:</strong> transferência de dados pessoais para país estrangeiro ou organismo internacional do qual o país seja membro.
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Ao acessar e/ou utilizar o site do <span className={classes.imagineText}>Imagine</span>, o Usuário declara estar fornecendo informações próprias e verdadeiras, bem como, ter no mínimo 18 (dezoito) anos com capacidade plena e expressa para a aceitação dos termos e condições desta Política de Privacidade de Dados, para todos os fins de direito.</strong>
            </Typography>
            <Typography variant="body2" paragraph>
              Caso o Usuário não se enquadre na descrição acima e/ou não concorde, ainda que em parte, com os termos e condições contidos nesta Política de Privacidade, não deverá acessar e/ou utilizar quaisquer serviços oferecidos pela plataforma <span className={classes.imagineText}>Imagine</span>, para maiores informações, poderá nos consultar, por meio da caixa <a className={classes.link} href={'mailto:' + 'suporte@imagineinovacao.com.br'}>suporte@imagineinovacao.com.br</a>.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>3. Do Imagine</Typography>
            <Typography variant="body2" paragraph>
              O intuito da Plataforma Imagine é promover a participação direta dos Colaboradores no processo de inovação e desenvolvimento da Empresa, ao cadastrar uma ideia.
            </Typography>
            <Typography variant="body2" paragraph>
              Para a participação e acesso à plataforma <span className={classes.imagineText}>Imagine</span> é necessário informar, geralmente, alguns dados, como: Nome completo; CPF; data de nascimento; gênero; Estado/Cidade; celular; e-mail pessoal. 
            </Typography>
            <Typography variant="body2" paragraph>
              A presente Política de Segurança visa garantir a proteção, a manutenção da privacidade, integridade, disponibilidade e confidencialidade das informações para aqueles que possuem acesso à plataforma <span className={classes.imagineText}>Imagine</span>.
            </Typography>
            <Typography variant="body2">
              No momento em que o Usuário preencher os campos e salvar o conteúdo, o site coletará os dados indicados, em conformidade com o consentimento (art. 9 da LGPD), para a finalidade de:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>I.</strong>	Identificação pessoal de quem está participando da plataforma, para isso, serão coletados como obrigatórios durante o procedimento de cadastro: <br/> 
                    <strong>a)</strong> E-mail; <br /> 
                    <strong>b)</strong>	Nome completo
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>II.</strong> Serão coletados como dados opcionais durante o procedimento de cadastro: <br/> 
                    <strong>a)</strong>	Unidade de negócio; <br/> 
                    <strong>b)</strong>	Local de trabalho; <br /> 
                    <strong>c)</strong>	Área de atuação; <br/>
                    <strong>d)</strong>	Foto; <br/> 
                    <strong>e)</strong>	Função; <br /> 
                    <strong>f)</strong>	Biografia; <br/>
                    <strong>g)</strong>	Telefone; <br/>
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>III.</strong> Os dados abaixo podem ser coletados para maior segurança e controle  e, quando coletados, permanecerão armazenados por até 6 (seis) meses: <br/> 
                    <strong>a)</strong>	Endereço de IP; <br/> 
                    <strong>b)</strong>	Data do acesso; <br /> 
                    <strong>c)</strong>	Hora do acesso; <br/>
                  </Typography>
                }/>
              </ListItem>
            </List>
            <Typography variant="body2" paragraph>
              Por vezes, os dados podem ser coletados via integração direta com plataforma específica, Premmiar, que funciona como troca de pontos adquiridos, mas que apenas são compartilhados por meio de opt in .
            </Typography>
            <Typography variant="body2" paragraph>
              Em todos os casos, o número de celular e e-mail são solicitados para o envio de informações restritas a plataforma, quando o Usuário permitir ser notificado para tanto. Caso o usuário não concorde com o envio de notificações, é possível desabilitar o envio em “configurações da conta” ou determinar quando deve ser notificado, conforme a tela:
            </Typography>
            <Box p="1rem 0px 2rem" display="flex" justifyContent="center">
              <img src={accountSettings} alt="Account Settings" />
            </Box>
            <Typography variant="body2" paragraph>
              Os Usuários inscritos no <span className={classes.imagineText}>Imagine</span> declaram, por meio desta, que estão cientes e concordam com as disposições do Termo de Uso e da Política de Privacidade, <strong>sobretudo no que se refere a isenção da responsabilidade da Empresa quanto ao desenvolvimento da ideia apresentada</strong>.
            </Typography>
            <Typography variant="body2" paragraph>
              A Empresa poderá implantar, a qualquer tempo e de acordo com seu exclusivo interesse, as Ideias que os Colaboradores apresentarem no IMAGINE, sem qualquer tipo de restrição, tendo em vista que o resultado decorrente da Ideia apresentada será sempre de propriedade exclusiva da Empresa aplicável.
            </Typography>
            <Typography variant="body2" paragraph>
              O Colaborador que apresentar a Ideia não terá direito a qualquer remuneração adicional ao seu salário pela sua apresentação e/ou implantação. <span className={classes.underline}>Somente</span> terá direito à premiação específica caso a sua ideia seja escolhida como vencedora de um desafio, o qual terá as suas regras de premiação definidas de acordo com o critério estabelecido pela área criadora. 
            </Typography>
            <Typography variant="body2" paragraph>
              Outras premiações podem ser aplicadas aos participantes da plataforma com base em critérios previamente definidos e comunicados pelas áreas de negócio, desde que previamente e por escrito.
            </Typography>
            <Typography variant="body2" paragraph>
              Os Termos de Uso do <span className={classes.imagineText}>Plataforma Imagine</span> e as disposições de segurança da informação aqui previstas seguirão o disposto no contrato de prestação de serviços firmado entre o Grupo FCamara, como contratada, e a Empresa, na qualidade de Contratante (“Contrato”).
            </Typography>
            <Typography variant="body2" paragraph>
              O <span className={classes.imagineText}>Imagine</span> declara, ainda, que cumpre integralmente com as suas obrigações de sigilo, nos termos do Contrato e sob quaisquer Leis aplicáveis relativas ao tratamento de dados, de acordo com os requisitos da LGPD.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>4. Coleta de Dados</Typography>
            <Typography variant="body2" paragraph>
              O Usuário está ciente de que fornece todas as informações aplicáveis de forma consciente e voluntária, por meio de formulários de cadastro ou por meio do próprio site do <span className={classes.imagineText}>Imagine</span>, no geral. 
            </Typography>
            <Typography variant="body2" paragraph>
              Os dados pessoais solicitados serão mantidos em sigilo e apenas utilizados definidos nessa política. 
            </Typography>
            <Typography variant="body2" paragraph>
              O Usuário é responsável pelo fornecimento das suas informações, que serão consideradas como pessoais, tidas como verdadeiras e íntegras, em conformidade com as legislações vigentes.
            </Typography>
            <Typography variant="body2" paragraph>
              O Titular é responsável ainda pelo fornecimento dos seus dados pessoais devidamente atualizados, exatos e completos. 
            </Typography>
            <Typography variant="body2" paragraph>
              <strong>Sob nenhuma hipótese, o Operador e o Controlador serão responsáveis pela veracidade ou atualização de tais informações</strong> fornecidas pelo Titular. 
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>5. Armazenamento de Dados</Typography>
            <Typography variant="body2" paragraph>
              As informações coletadas do Titular poderão ser retidas pelo período compatível com a finalidade para as quais aquelas informações foram coletadas. Como por exemplo, enquanto o Usuário se manter ativo na plataforma <span className={classes.imagineText}>Imagine</span>, os dados continuarão armazenados.
            </Typography>
            <Typography variant="body2" paragraph>
              A partir do momento em que um Titular de dados exerça o seu direito de eliminação e/ou correção dos dados, estes originalmente não estarão armazenados pela plataforma a partir de 60 (sessenta) dias contados da inativação daquele ou, mediante exercício do direito de eliminação dos dados, com um prazo máximo de 5 (cinco) dias para a exlcusão definitiva.
            </Typography>
            <Typography variant="body2" paragraph>
              As informações coletadas são armazenadas no <span className={classes.imagineText}>Imagine</span> mediante servidor em Google Cloud. Ainda, o armazenamento do consentimento dado à esta Política será armazenado em repositório centralizado da AWS , bem como, para disparo de e-mails, até que o Usuário manifeste a sua vontade de exclusão dos dados da base, conforme dispõe o art. 12, 2, (a) da GDPR.
            </Typography>
            <Typography variant="body2">
              A Empresa utiliza os meios razoáveis de mercado e legalmente requeridos, para preservar a privacidade dos dados coletados. Desta forma, adota as seguintes precauções:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>I.</strong>	Quando necessário a Empresa utiliza os métodos padrão e de mercado para criptografar e anonimizar os dados coletados;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>II.</strong> A plataforma <span className={classes.imagineText}>Imagine</span> possui proteção contra acesso não autorizado a seus sistemas;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>III.</strong>	O <span className={classes.imagineText}>Imagine</span> somente autoriza o acesso de pessoas previamente estabelecidas ao local onde são armazenadas as informações coletadas; 
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>IV.</strong> Aqueles que entram em contato com as informações se comprometem a manter sigilo absoluto. A quebra do sigilo acarretará responsabilidade civil e o responsável será responderá nos moldes da legislação brasileira.
                  </Typography>
                }/>
              </ListItem>
            </List>
            <Typography variant="body2" paragraph>
              <strong className={classes.underline}>São adotados os melhores esforços, no sentido de preservar a privacidade dos dados dos Usuários. Entretanto, nenhum site é totalmente seguro e o Imagine não pode garantir integralmente que todas as informações que trafegam nas Páginas não sejam alvo de acessos não autorizados, perpetrados por meio de métodos desenvolvidos para obter informações de forma indevida.</strong>
            </Typography>
            <Typography variant="body2">
              Por esse motivo, incentivamos os Usuários a tomarem as medidas apropriadas para se protegerem, como, por exemplo, mantendo confidenciais todos os nomes e senhas criados.
            </Typography>  
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>6. Compartilhamento de Dados</Typography>
            <Typography variant="body2">
              Os dados pessoais coletados, nas seguintes situações e nos limites autorizados pela lei, poderão ser compartilhados: 
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>I.</strong>	Para propósitos administrativos como: pesquisa, planejamento, desenvolvimento de serviços, segurança e gerenciamento de risco;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>II.</strong> Para fins de entrega de serviço, se aplicável;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>III.</strong>	Quando necessário para cumprir uma obrigação legal, determinação de autoridade competente ou decisão judicial. 
                  </Typography>
                }/>
              </ListItem>
            </List>
            <Typography variant="body2">
              Em alguns casos, os Dados Pessoais coletados podem ser utilizados com o objetivo de cumprir a legislação aplicável, ou por meio de ordem, ou intimação judicial, ou administrativa. A divulgação legal poderá ser feita para: 
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>I.</strong>	Cumprir com a legislação; 
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>II.</strong> Investigar, impedir ou tomar medidas relacionadas a cooperação com órgãos públicos ou para proteger a segurança nacional;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>III.</strong>	Execução de contratos;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>IV.</strong> Investigação e defesa de alegações de terceiros; 
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>V.</strong> Proteção da segurança ou integridade dos serviços.
                  </Typography>
                }/>
              </ListItem>
            </List>
            <Typography variant="body2" paragraph>
              Dessa forma, temos claro que para cada situação um dado poderá ser compartilhado com Terceiro, como é o caso da empresa Premmiar que utiliza de sistema de pontuação específico e que realiza a interface na coleta de dados, aplicável apenas para Usuário que consentirem com o compartilhamento via opt in.
            </Typography>
            <Typography variant="body2" paragraph>
              Quando o compartilhamento de dados se der de maneira internacional, a Empresa garante que contempla os requisitos adicionais da LGPD, em consonância ao art.4º, IV, que direciona ao Controlador a necessidade do país de destino ter equivalente lei de proteção aos dados.
            </Typography>
            <Typography variant="body2" paragraph>
              Em cada caso, em que seja necessário o compartilhamento de dados com Terceiros, terá especificado no objeto do contrato, ou ainda, via consentimento das Partes, servindo como prova: e-mails, mensagens, reuniões, gravações e afins, se aplicáveis.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>7. Direitos do Titular</Typography>
            <Typography variant="body2">
              Em cumprimento à regulamentação aplicável, no que diz respeito ao tratamento de dados pessoais, art. 12º, 1, GDPR, a Empresa respeita e garante ao Usuário, a possibilidade de apresentação de solicitações baseadas nos seguintes direitos:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>I.</strong>	A confirmação da existência de tratamento;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>II.</strong> O acesso aos próprios dados pessoais tratados;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>III.</strong> A correção de dados incompletos, inexatos ou desatualizados, sem demora injustificada;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>IV.</strong> A anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade; 
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>V.</strong> A portabilidade de seus dados a outro fornecedor de serviço ou produto, mediante requisição expressa pelo Usuário, conforme o disposto no art. 20º, 1 da GDPR;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>VI.</strong> A eliminação dos dados tratados, mediante solicitação do Titular;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>VII.</strong> A obtenção de informações sobre as entidades públicas ou privadas com as quais a Empresa compartilhou seus dados;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>VIII.</strong> A informação sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre as consequências, em caso de negativa;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>IX.</strong> A revogação do consentimento;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>X.</strong> O direito de prestar queixa a uma autoridade de controle, conforme dispõe o art.13º, 2, (d).
                  </Typography>
                }/>
              </ListItem>
            </List>
            <Typography variant="body2">
              Ainda, conforme o disposto no art. 15, 1 da GDPR, o Usuário tem o direito de obter acesso às informações sobre:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>1</strong> As finalidades do processamento;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>2</strong> As categorias de dados pessoais em questão;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>3</strong> Os destinatários ou categorias de destinatários aos quais os dados pessoais foram ou serão divulgados, em particular destinatários em países terceiros ou organizações internacionais;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>4</strong> Se possível, o período previsto de conservação dos dados pessoais ou, se não for possível, os critérios utilizados para determinar esse período;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>5</strong>A existência de tomada de decisão automatizada, incluindo criação de perfis, referida no Artigo 22º 1 e 4 e, pelo menos nesses casos, informações significativas sobre a lógica envolvida, bem como o significado e as consequências previstas de tal tratamento para o titular dos dados.
                  </Typography>
                }/>
              </ListItem>
            </List>
            <Typography variant="body2" paragraph>
              Para tanto, é necessária a sua identificação e o seu interesse legítimo, sendo assim, a Empresa poderá solicitar a confirmação de alguns dados.
            </Typography>
            <Typography variant="body2" paragraph>
              O <span className={classes.imagineText}>Imagine</span> se coloca a disposição para cumprimento dos direitos dos titulares de dados, por meio solicitação do titular a ser enviada pelo endereço de e-mail <a className={classes.link} href={'mailto:' + 'suporte@imagineinovacao.com.br'}>suporte@imagineinovacao.com.br</a>.
            </Typography>
            <Typography variant="body2" paragraph>
              O Usuário fica ciente de que a exclusão das informações essenciais para a gestão de sua conta na plataforma <span className={classes.imagineText}>Imagine</span> implicará no término de seu cadastro, com consequente cancelamento dos serviços e produtos disponibilizados, observando-se as regras e disposições contidas nos respectivos documentos que respaldaram o referido relacionamento. Ou, a exclusão de informações essenciais poderá deixar de atender às finalidades desejadas para o uso da plataforma.
            </Typography>
            <Typography variant="body2" paragraph>
              A Empresa empreenderá todos os esforços para atender tais pedidos no menor espaço de tempo possível, conforme dispõe o art. 7º, 3 da GDPR. 
            </Typography>
            <Typography variant="body2" paragraph>
              Ainda, a Controladora poderá recusar-se a agir, conforme determinados exercícios do Usuário, respeitando o prazo de 30 (trinta) dias estabelecido na GDPR para resposta, art.12º, 2 e 5, (b).
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>8. Atualizações</Typography>
            <Typography variant="body2" paragraph>
              Essa política possui uma periodicidade de revisão de, no mínimo, a cada 12 (doze) meses, conforme eventual necessidade, assim dispõe o art. 24º, 1, da GDPR, para fins de adequação e conformidade legal, a política deverá sempre refletir a realidade atual da plataforma <span className={classes.imagineText}>Imagine</span>.
            </Typography>
            <Typography variant="body2" paragraph>
              Todas as alterações serão consideradas válidas, eficazes e vinculantes, e dependendo do tipo de alteração, poderemos avisar através de divulgação em nossos Sites, Aplicativos ou por e-mail, desde já dando consentimento para tal comunicação, em conformidade com o art. 19º da GPDR. 
            </Typography>
            <Typography variant="body2" paragraph>
              Caberá sempre ao Usuário verificar a versão atualizada dessa Política em nossos canais, podendo revogar o seu consentimento, de acordo com o capítulo “Direitos do Titular”.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>9. Cookies</Typography>
            <Typography variant="body2" paragraph>
              Em linhas gerais, um Cookie é um pequeno arquivo de texto que armazena informações em seu dispositivo eletrônico. Como por exemplo, em seu computador, celular ou outro dispositivo, com o objetivo principal de facilitar a experiência do Usuário.
            </Typography>
            <Typography variant="body2" paragraph>
              Os cookies são utilizados especialmente, para adaptação em nossos sites, aplicativos e do seu conteúdo para melhor atender os interesses e necessidades dos Usuários.
            </Typography>
            <Typography variant="body2" paragraph>
              Ainda, são utilizados para otimização das atividades e experiências futuras com a plataforma <span className={classes.imagineText}>Imagine</span>. 
            </Typography>
            <Typography variant="body2" paragraph>
              O <span className={classes.imagineText}>Imagine</span> utiliza cookies para facilitar o uso e adaptar as páginas aos seus interesses e necessidades, bem como, para compilar informações sobre a utilização de nossos sites e serviços, auxiliando a melhorar suas estruturas e seus conteúdos. 
            </Typography>
            <Typography variant="body2" paragraph>
              A Empresa utilizará tais registros, comumente conhecidos como “logs”, a princípio, apenas para fins estatísticos ou para investigação de fraudes em cadastros. 
            </Typography>
            <Typography variant="body2">
              Os cookies também podem ser utilizados para acelerar suas atividades e experiências futuras nas Páginas, bem como, mantendo o cadastro salvo para acessos futuros, facilitando a autenticação do Usuário.
            </Typography>

            <TableContainer component={Paper} elevation={0} style={{ margin: '2rem 0' }}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
                    <TableCell>
                      <Typography variant="body2" style={{ color: theme.palette.neutrals.high.main, fontWeight: 700 }}>Tipos de cookies</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" style={{ color: theme.palette.neutrals.high.main, fontWeight: 700 }}>O que eles fazem?</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ border: `1px solid ${theme.palette.neutrals.high.medium}` }}>
                    <TableCell>Necessários*</TableCell>
                    <TableCell>Esses cookies são associados ao nome completo e e-mail do usuário; como interagem com a plataforma Imagine. Único cookie de fato obrigatório para um bom funcionamento da plataforma.</TableCell>
                  </TableRow>
                  <TableRow style={{ border: `1px solid ${theme.palette.neutrals.high.medium}` }}>
                    <TableCell>Desempenhos</TableCell>
                    <TableCell>São aqueles que nos auxiliam a entender como o Usuário interage com nossos Sites, Aplicativos e os seus respectivos produtos e serviços, apresentando-nos dados sobre áreas visitas, tempo de visita e erros de acesso, por exemplo.</TableCell>
                  </TableRow>
                  <TableRow style={{ border: `1px solid ${theme.palette.neutrals.high.medium}` }}>
                    <TableCell>Funcionais</TableCell>
                    <TableCell>Esses cookies permitem que a plataforma Imagine, se lembre de suas escolhas, para proporcionar uma experiência mais personalizada.</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="body2" paragraph>
              Após o consentimento do Titular para a utilização de cookies, a Empresa armazenará um cookie em seu dispositivo para lembrar-se na próxima sessão.
            </Typography>
            <Typography variant="body2" paragraph>
              A qualquer momento, o Usuário poderá revogar seu consentimento quanto aos cookies coletados, pelo que deverá apagar os cookies das Páginas do <span className={classes.imagineText}>Imagine</span>, utilizando as configurações de seu navegador de preferência. 
            </Typography>
            <Typography variant="body2">
              Para mais informações sobre como proceder em relação à gestão dos cookies nos navegadores:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>•</strong>{' '}
                    <a 
                      className={classes.link} 
                      target="_blank" 
                      rel="noreferrer" 
                      href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
                    >
                      Internet Explorer
                    </a>
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>•</strong>{' '}
                    <a 
                      className={classes.link} 
                      target="_blank" 
                      rel="noreferrer" 
                      href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam"
                    >
                      Mozilla Firefox
                    </a>
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>•</strong>{' '}
                    <a 
                      className={classes.link} 
                      target="_blank" 
                      rel="noreferrer" 
                      href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                    >
                      Google Chrome
                    </a>
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>•</strong>{' '}
                    <a 
                      className={classes.link} 
                      target="_blank" 
                      rel="noreferrer" 
                      href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                    >
                      Safari
                    </a>
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>•</strong>{' '}
                    <a 
                      className={classes.link} 
                      target="_blank" 
                      rel="noreferrer"
                      href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                    >
                      Microsoft Edge
                    </a>
                  </Typography>
                }/>
              </ListItem>
            </List>
            <Typography variant="body2" paragraph>
              Por fim, lembramos que, <strong className={classes.underline}>caso o Usuário não aceite alguns cookies das páginas, certos serviços poderão não funcionar de maneira ideal</strong>.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>10. Segurança</Typography>
            <Typography variant="body2" paragraph>
              As informações e dados coletados serão armazenados em observância aos rigorosos padrões de segurança e confiabilidade, através de medidas técnias, administrativas, entre outras, adequeadas à privacidade e proteção de dados pessoais. 
            </Typography>
            <Typography variant="body2" paragraph>
              No momento em que esta política foi escrita, utilizamos de repositório centralizado do Google Cloud, bem como AWS, conforme supramencionado, ferramenta utilizada pelo grande mercado  para a automação do Marketing Digital, como forma de decisão automatizada, conforme dispõe o art. 13º, 2, (f) da GDPR.
            </Typography>
            <Typography variant="body2">
              Adicionalmente, é importante a ciência dos Usuários de que:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>I.</strong> Utilizamos robusta e atual tecnologia para, quando necessário, adotar processos técnicos de criptografia e anonimização dos seus dados pessoais;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>II.</strong> Apenas as pessoas autorizadas e vinculadas ao desenvolvimento das nossas atividades, com base nas finalidades previstas nesta Política, terão acesso ao local, seja físico ou lógico, de armazenamento dos dados pessoais e, por conseguinte, aos respectivos dados;
                  </Typography>
                }/>
              </ListItem>
              <ListItem>
                <ListItemText primary={
                  <Typography variant="body2">
                    <strong>III.</strong> Todos os nossos colaboradores, terceiros e parceiros reconhecem e se empenham com a privacidade e a proteção dos dados pessoais, comprometendo-se, sob as penas da lei, a observar no manuseio e tratamento dos seus dados pessoais, os padrões descritos nessa Política e na legislação aplicável;
                  </Typography>
                }/>
              </ListItem>
            </List>
            <Typography variant="body2" paragraph>
              De qualquer maneira, a Plataforma <span className={classes.imagineText}>Imagine</span> zela pela segurança da informação e tecnologia adequada para tanto. Dessa forma, sempre armazenará as informações em repositório seguro, em países com legislação equiparada e/ou superior à Lei Geral de Proteção de dados, conforme dispõe o art. 33, I da Lei Geral de Proteção de Dados.
            </Typography>
            <Typography variant="body2" paragraph>
              Adotamos todas as medidas necessárias com foco na preservação da privacidade e da proteção dos dados pessoais, entretanto, <strong className={classes.underline}>é importante destacar que todo o processo de transmissão de informações é passível de ser alvo de acessos não autorizados</strong>, através de métodos com vírus, invasões do banco de dados do site, ou, ainda, por falhas técnicas.
            </Typography>
            <Typography variant="body2" paragraph>
              Na remota e eventual hipótese da ocorrência de tal cenário, envidaremos todos os nossos esforços para colocar em prática os nossos planos de resposta a incidentes e remedição, em total observância e conformidade com as disposições legais e normativas aplicáveis.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" paragraph className={classes.subtitle}>11. Contato e Foro de Eleição/Legislação</Typography>
            <Typography variant="body2" paragraph>
              A Empresa se coloca a disposição para solução de quaisquer dúvidas, ou solicitações, dos titulares de dados, pelo endereço de e-mail <a className={classes.link} href={'mailto:' + 'suporte@imagineinovacao.com.br'}>suporte@imagineinovacao.com.br</a>.
            </Typography>
            <Typography variant="body2" paragraph>
              Esta Política será regida, interpretada e executada de acordo com as Leis da República Federativa do Brasil, especialmente a Lei nº 13.709/2018, independentemente das Leis de outros estados ou Países, sendo competente o foro Central da Comarca de São Paulo Capital para dirimir qualquer dúvida decorrente deste documento.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <FooterTerms />
    </>
  );
}
