import React from 'react';

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { StatusIdea, StepIdea } from '../../components';
import { tableCss } from './TableCss';
import { dateFormat, truncate } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ideaActions, fluxOfIdeasActions } from '../../actions';
import { TableMenu } from './TableMenu';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import { CustomTooltip } from '../Shared';
import config from '../../config';
import { useCanEditableIdea } from '../../hooks';

function IdeaTable({ idea, ideas, paging, history, dialog, setIdea }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const { ideasStepsFlux } = useSelector(state => state.domains);
  const styleProps = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(styleProps);

  const columns = [
    { id: 'code', label: t('codigo') },
    { id: 'date', label: t('data') },
    { id: 'title', label: t('titulo_da_ideia') },
    { id: 'challenge', label: t('desafio') },
    { id: 'unitLocal', label: t('unidade_de_negocio') },
    { id: 'status', label: config.SHOW_IDEAS_BY_STEP_FLUX ? t('etapas') : t('status') },
    { id: 'actions', label: '' },
  ];

  function selectRow(idea) {
    if (idea.statusIdea?.code !== 'DRAFT') {
      // dispatch(ideaActions.getIdeaDetails(idea._id, 'general'));
    }
  }

  function openDialogDeleteDraft(idea) {
    dialog.setOpentDialog(true);
    setIdea(idea);
  }

  function handleView(id) {
    history.push(`/idea/${id}/general`);
  }

  function handleEdit(id) {
    history.push(`/idea-edit/${id}`);
  }

  function handleChangePage(event, page) {
    let newPage = page + 1;

    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      dispatch(
        fluxOfIdeasActions.getIdeasUserStepFlux(
          newPage,
          paging.sort,
          paging.query,
          paging.limit,
          ideasStepsFlux.map(({ _id }) => _id)
        )
      );
    } else {
      dispatch(
        ideaActions.getIdeasUser(
          newPage,
          paging.sort,
          paging.search,
          paging.limit,
          paging.statusCode
        )
      );
    }
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;

    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      dispatch(
        fluxOfIdeasActions.getIdeasUserStepFlux(
          1,
          paging.sort,
          paging.query,
          limit,
          ideasStepsFlux.map(({ _id }) => _id)
        )
      );
    } else {
      dispatch(
        ideaActions.getIdeasUser(
          1,
          paging.sort,
          paging.search,
          limit,
          paging.statusCode
        )
      );
    }
  }


  function StepFluxEditButton({ ideaStepId, idea }) {
    const shouldRender = useCanEditableIdea(ideaStepId) || idea.statusIdea?.code === "DRAFT";

    if (shouldRender) {
      return (
        <ListItem button onClick={() => handleEdit(idea._id)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography>{t('editar')}</Typography>} />
        </ListItem>
      )
    } else {
      return (<></>);
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <Paper className={tableClasses.paperRoot}>
        <TableContainer>
          <Table stickyHeader aria-label="idea table" classes={{ root: tableClasses.table }}>
            <TableHead style={{ height: '70px' }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column._id} classes={{ root: tableClasses.tableCellHeader }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ideas && ideas.map((idea) => (
                <TableRow
                  hover
                  key={idea._id}
                  onClick={() => selectRow(idea)}
                  style={{ cursor: 'pointer', height: 60 }}
                  classes={{ root: tableClasses.tableRow }}
                >
                  <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
                    {idea.code}
                  </TableCell>
                  <TableCell classes={{ root: tableClasses.tableCell }}>
                    {dateFormat(idea.createdAt, language)}
                  </TableCell>
                  <TableCell
                    className={tableClasses.oneLine}
                    classes={{ root: tableClasses.tableCell }}
                  >
                    <CustomTooltip 
                      title={idea.name} 
                      aria-label={idea.name} 
                      placement="bottom-start"
                    >
                      <Typography 
                        variant="subtitle2" 
                        className={tableClasses.link} 
                        onClick={() => handleView(idea._id)}
                      >
                        {truncate(idea.name, 30)}
                      </Typography>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell
                    className={tableClasses.oneLine}
                    classes={{ root: tableClasses.tableCell }}
                  >
                    {idea.challenge && idea.challenge.name}
                  </TableCell>
                  <TableCell
                    className={tableClasses.oneLine}
                    classes={{ root: tableClasses.tableCell }}
                  >
                    {idea.businessUnitLocal && idea.businessUnitLocal.length > 0 &&
                      truncate(idea.businessUnitLocal[0]?.local?.name, 30)}
                  </TableCell>
                  <TableCell classes={{ root: tableClasses.tableCell }}>
                    {config.SHOW_IDEAS_BY_STEP_FLUX ? (
                      <StepIdea step={ideasStepsFlux?.find(({ _id }) => _id === idea?.currentStepFlux?.ideaStepFlux) || idea?.statusIdea} />
                    ) : idea.statusIdea && (
                      <StatusIdea status={idea?.statusIdea} />
                    )}
                  </TableCell>
                  <TableMenu>
                    <List>
                      {config.SHOW_IDEAS_BY_STEP_FLUX ? (
                        <ListItem onClick={() => handleView(idea._id)} button>
                          <ListItemIcon>
                            <ViewIcon />
                          </ListItemIcon>
                          <ListItemText primary={<Typography>{t('visualizar')}</Typography>}
                          />
                        </ListItem>
                      ) : idea.statusIdea?.code !== 'DRAFT' && (
                        <ListItem onClick={() => handleView(idea._id)} button>
                          <ListItemIcon>
                            <ViewIcon />
                          </ListItemIcon>
                          <ListItemText primary={<Typography>{t('visualizar')}</Typography>} />
                        </ListItem>
                      )}
                      {config.SHOW_IDEAS_BY_STEP_FLUX ? (
                        <StepFluxEditButton ideaStepId={idea.currentStepFlux?.ideaStepFlux} idea={idea}/>
                      ) : (idea.statusIdea?.code !== 'CANCELED' && 
                            idea.statusIdea?.code !== 'CONCLUDED' && 
                            idea.statusIdea?.code !== 'UNQUALIFIED') && (
                        <ListItem button onClick={() => handleEdit(idea._id)}>
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText primary={<Typography>{t('editar')}</Typography>}
                          />
                        </ListItem>
                      )}

                      {config.SHOW_IDEAS_BY_STEP_FLUX ? (
                        <ListItem button onClick={() => openDialogDeleteDraft(idea)}>
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText primary={<Typography>{t('deletar')}</Typography>} />
                        </ListItem>
                      ) : (idea.statusIdea?.code === 'DRAFT' && (
                        <ListItem onClick={() => openDialogDeleteDraft(idea)} button>
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={<Typography>
                              {t('deletar')}
                            </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </TableMenu>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          classes={{
            input: tableClasses.paginationInput,
            spacer: tableClasses.paginationSpacer,
            caption: tableClasses.paginationCaption,
            toolbar: tableClasses.paginationToolbar,
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          count={paging.total}
          rowsPerPage={paging.limit}
          page={paging.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('exibindo')}
         style={{ zIndex: 10, position: 'relative'}}
        />
      </Paper>
   </div>
  );
}

export { IdeaTable };
