import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core';
import { Person, Delete, AddCircle } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { articleActions, userFunctionActions } from '../../actions';
import { uniqueId } from 'lodash';
import { AvatarIconWithText } from '../Shared';
import { Card } from './articlesStyles';

function ArticleAuthors({ isEdit=false }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { users } = useSelector((state) => state.userFunction);
  const { articleDetails: { authors } } = useSelector((state) => state.articlesReducer);

  const [authorFields, setAuthorFields] = useState([
    { id: uniqueId(), selectedAuthor: null },
  ]);

  useEffect(() => {
    if(isEdit) {
      setAuthorFields(authors.map((author) => ({ id: uniqueId(), selectedAuthor: {name: author.name, id: author._id} })));
    }
  }, []);

  useEffect(() => {
    dispatch(userFunctionActions.getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    const authorsIds = authorFields.filter(({ selectedAuthor }) => selectedAuthor !== null).map(({ selectedAuthor: { id } }) => id);
    dispatch(articleActions.setAuthors(authorsIds, isEdit));
  }, [authorFields]);

  const getAvailableOptions = (fieldId) => {
    const selectedAuthorsIds = authorFields
      .filter((field) => field.id !== fieldId && field.selectedAuthor)
      .map((field) => field.selectedAuthor.id);

    return users.filter((user) => !selectedAuthorsIds.includes(user.id));
  };

  const handleAddAuthorField = () => {
    setAuthorFields((prevFields) => [
      ...prevFields,
      { id: uniqueId(), selectedAuthor: null },
    ]);
  };

  const handleAddAuthor = (author, fieldId) => {
    setAuthorFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId ? { ...field, selectedAuthor: author } : field
      )
    );
  };

  const handleRemoveAuthor = (fieldId) => {
    setAuthorFields((prevFields) => prevFields.filter((field) => field.id !== fieldId));

  };

  const handleInputChange = (reason, fieldId) => {
    if (reason === 'clear') {
      handleAddAuthor(null, fieldId);
    }
  };

  return (
    <Card elevation={0}>
      <AvatarIconWithText
        icon={<Person color="primary" />}
        text={t('autores')}
      />
      <Box paddingTop="2rem">
        {authorFields.map((field, index) => (
          <Grid container spacing={3} key={field.id}>
            <Grid item xs={authorFields?.length > 1 ? 11 : 12}>
              <Autocomplete
                id={`users-autocomplete-${field.id}`}
                onInputChange={(e, v, r) => handleInputChange(e, v, r, field.id)}
                options={getAvailableOptions(field.id)}
                getOptionLabel={(user) => user.name}
                value={field.selectedAuthor}
                onChange={(event, value) => {
                  if (value) {
                    handleAddAuthor(value, field.id);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t('participante')} variant="filled" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={index > 1 && 1}>
              {index !== 0 && (
                <IconButton
                  style={{ marginLeft: 10 }}
                  onClick={() => handleRemoveAuthor(field.id)}
                >
                  <Delete />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
        {authorFields[0]?.selectedAuthor !== null && <Button
          startIcon={<AddCircle />}
          variant="text"
          color="primary"
          style={{ marginTop: 10 }}
          onClick={handleAddAuthorField}
        >
          {t('adicionar_autor')}
        </Button>}
      </Box>
    </Card>
  );
}

export { ArticleAuthors };
