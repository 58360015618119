import axios from 'axios';
import config from '../config';
import { handleResponse, isValidObjectId, requestHeaders } from '../helpers';

export const generalEvaluationService = {
  getEvaluationGroup,
  getEvaluationGroupById,
  getAllEvaluationsByGroup,
  createEvaluationGroup,
  createGeneralEvaluation,
  updateEvaluationGroup,
  updateGeneralEvaluation,
  deleteGeneralEvaluation,
  uploadCriteriaIcon,
  deleteGeneralEvaluationGroup
}

function getEvaluationGroup(page, sort, limit) {
  return axios
    .get(`${config.URL_BASE}/personalized-evaluation-group?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`, requestHeaders());
}

function getEvaluationGroupById(id) {
  return axios
    .get(`${config.URL_BASE}/personalized-evaluation-group/${id}`, requestHeaders());
}

function getAllEvaluationsByGroup(id) {
  return axios
    .get(`${config.URL_BASE}/personalized-evaluation/by-group/${id}`, requestHeaders());
}

function createEvaluationGroup(data) {
  return axios
    .post(`${config.URL_BASE}/personalized-evaluation-group`, data, requestHeaders());
}

async function createGeneralEvaluation(data) {
  const response = await axios.post(`${config.URL_BASE}/personalized-evaluation`, data, requestHeaders());
  return handleResponse(response.data);
}

function updateEvaluationGroup(id, data) {
  return axios
    .put(`${config.URL_BASE}/personalized-evaluation-group/${id}`, data, requestHeaders());
}

function updateGeneralEvaluation(data) {
  return axios
    .put(`${config.URL_BASE}/personalized-evaluation/update-many`, data, requestHeaders());
}

function deleteGeneralEvaluation(data) {
  const validsIds = data.filter(id => isValidObjectId(id));

  return axios
    .post(`${config.URL_BASE}/personalized-evaluation/delete-many`, validsIds, requestHeaders());
}

function deleteGeneralEvaluationGroup(groupId) {
  return axios
    .delete(`${config.URL_BASE}/personalized-evaluation-group/${groupId}`, requestHeaders());
}

function uploadCriteriaIcon(icons) {
  const iconsWithBlob = icons.filter(({ blob }) => blob);

  const promises = iconsWithBlob.map(({ blob, criteriaId, name }) => {
    const data = new FormData();

    data.append('file', blob, name);

    return axios.post(
      `${config.URL_BASE}/uploads/personalized-evaluation/${criteriaId}`,
      data,
      requestHeaders()
    );
  });

  return Promise.all(promises);
}
