import React, { useEffect } from 'react'
import { makeStyles, Container, Grid, Box } from '@material-ui/core';
import {
  Search,
  BackNavAdmin,
  DialogAllUserFunctions,
  UserCards,
  EmptyState,
  SkeletonTable
} from '../../components';
import { useWindowSize } from '../../hooks';
import config from '../../config';
import { useSelector, useDispatch } from "react-redux";
import { UsersFilter } from './../../components/ManageUsers/UsersFilter';
import { manageUsersActions, userFunctionActions } from './../../actions'
import { UsersTable } from './../../components/Tables/UsersTable'

import { useTranslation } from 'react-i18next';
import { usersIcon } from '../../assets/imgs';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  button: {
    float: "right"
  }
}));

export const UsersPage = () => {
  const classes = useStyles();
  const [width] = useWindowSize();
  const dispatch = useDispatch();
  const { users, paging, loading } = useSelector((state) => state.manageUsers);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(manageUsersActions.getUsers(
      paging.search,
      1,
      paging.sort,
      paging.limit,
    ));
    dispatch(userFunctionActions.clearAdminUserEdit())
    dispatch(userFunctionActions.clearState())
  }, [])

  function sendQuery(query) {
    dispatch(manageUsersActions.getFilteredUsers(
      query,
      1,
      paging.sort,
      paging.limit,
      paging.statusCode
    ));
  }

  return (
    <>
      <DialogAllUserFunctions />
      <BackNavAdmin title={t('usuarios')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <UsersFilter />
              <Search 
                placeholder={t('pesquisar')} 
                value={paging.search} 
                query={sendQuery}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <SkeletonTable cols={4} />
            ) : (
              <>
                {users.length > 0 ? (
                  width > config.RESPONSIVE_MOBILE ? <UsersTable /> : <UserCards />
                ) : (
                  <Box paddingTop="2rem">
                    <EmptyState
                      icon={usersIcon}
                      description={t('ainda_nao_ha_usuarios_por_aqui')}
                    />
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
