import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config';
import { fluxOfIdeasActions, ideaActions } from '../../../actions';

export function useMyIdeasController() {
  const dispatch = useDispatch();
  const { ideasStepsFlux } = useSelector(state => state.domains);
  const { ideas: ideasDefault, paging: pagingDefault, loading: loadingDefault } = useSelector((state) => state.idea);
  const { ideasFlux, paging: pagingFlux, loadingFlux } = useSelector((state) => state.fluxIdeas);
  
  const ideas = config.SHOW_IDEAS_BY_STEP_FLUX ? ideasFlux : ideasDefault;
  const paging = config.SHOW_IDEAS_BY_STEP_FLUX ? pagingFlux : pagingDefault;
  const loading = config.SHOW_IDEAS_BY_STEP_FLUX ? loadingFlux : loadingDefault;

  useEffect(() => {
    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      dispatch(
        fluxOfIdeasActions.getIdeasUserStepFlux(
          pagingFlux.page,
          pagingFlux.sort,
          pagingFlux.query,
          pagingFlux.limit,
          ideasStepsFlux.map(({ _id }) => _id)
        )
      );
    } else {
      dispatch(
        ideaActions.getIdeasUser(
          pagingDefault.page,
          pagingDefault.sort,
          pagingDefault.search,
          pagingDefault.limit,
          pagingDefault.statusCode
        )
      );
    }
  }, [dispatch]);

  function loadMoreIdeas() {
    if (!loading) {
      var newPage = pagingDefault.page === 1 ? 2 : pagingDefault.page;

      if (config.SHOW_IDEAS_BY_STEP_FLUX) {
        dispatch(
          fluxOfIdeasActions.getIdeasUserStepFlux(
            newPage,
            pagingFlux.sort,
            pagingFlux.query,
            pagingFlux.limit,
            ideasStepsFlux.map(({ _id }) => _id)
          )
        );
      } else {
        dispatch(
          ideaActions.getIdeasUserMobile(
            newPage,
            pagingDefault.sort,
            pagingDefault.search,
            pagingDefault.limit,
            pagingDefault.statusCode
          )
        );
      }
    }
  }

  function searchIdea(query) {
    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      dispatch(
        fluxOfIdeasActions.getIdeasUserStepFlux(
          pagingFlux.page,
          pagingFlux.sort,
          query,
          pagingFlux.limit,
          ideasStepsFlux.map(({ _id }) => _id)
        )
      );
    } else {
      dispatch(
        ideaActions.getIdeasUser(
          paging.page,
          paging.sort,
          query,
          paging.limit,
          paging.statusCode
        )
      );
    }
  }

  return {
    loadMoreIdeas,
    searchIdea,
    ideas,
    loading,
    paging
  }
}