import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'date-fns';
import './i18nextInit';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { theme } from './theme';
import DateFnsUtils from '@date-io/date-fns';
import { selectedLanguageTheme } from './helpers/languageTheme';
import { selectedLocale } from './helpers/localeTheme';
import { store } from './helpers';
import * as serviceWorker from './serviceWorker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

let persistor = persistStore(store)

const Index = () => {
  const selectedLanguage = window.localStorage.getItem('i18nextLng') || 'pt_BR';
  const language = useSelector((state) => state.language);
  const [languageTheme, setLanguageTheme] = useState(selectedLanguage);
  const [localeTheme, setLocaleTheme] = useState(selectedLanguage);

  useEffect(() => {
    setLanguageTheme(selectedLanguageTheme(language));
    setLocaleTheme(selectedLocale(language));
  }, [language]);

  return (
    <ThemeProvider theme={createTheme(theme, languageTheme)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={selectedLocale(localeTheme.code)}>
        <App />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Index />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
