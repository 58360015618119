import { Box, Button, DialogActions, Grid, IconButton, makeStyles, TextField } from "@material-ui/core";
import { ReactComponent as OutBox } from '../../../assets/imgs/outbox.svg';
import { useTranslation } from "react-i18next";
import { AvatarIconWithText, MultiAreaCascade } from '../../Shared';
import { useDispatch, useSelector } from "react-redux";
import { delegateStepIdeaActions } from "../../../actions";
import { useEffect } from "react";
import { Add, ArrowBack, Delete, FiberManualRecord } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import { useMemo } from "react";
import { uniqueId } from "lodash";


const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 20,
    paddingBottom: theme.spacing(5)
  },
  avatarIcon: {
    backgroundColor: theme.palette.primary.light,
    marginRight: 10,
  },
  about: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: '#f44336',
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  buttons: {
    display: 'flex',
    alingItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2)
  },
}));

export function UsersForm({ handleBack, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { observation, users, usersIds, userList } = useSelector(state => state.delegateStepIdea);
  const [userFiltered, setUsersFiltered] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setUsersFiltered(users.filter(user => !usersIds.some(({ _id }) => _id === user._id)));
  }, [users, usersIds]);



  useEffect(() => {
    dispatch(delegateStepIdeaActions.clearObservation());
    dispatch(delegateStepIdeaActions.clearUserIds());
    dispatch(delegateStepIdeaActions.clearUserFields());
    dispatch(delegateStepIdeaActions.getAllUsers());
  }, []);

  function handleChangeUsersIds(option) {
    dispatch(delegateStepIdeaActions.setUsersIds(option))
  }

  function handleAddUserField() {
    const fieldId = uniqueId();
    dispatch(delegateStepIdeaActions.addUserField(fieldId));
  }

  function handleRemoveUserField(id) {
    dispatch(delegateStepIdeaActions.removeUserField(id));
  }

  function handleChangeObservation({ target: { value } }) {
    dispatch(delegateStepIdeaActions.setObservation(value));
  }

  function handleSend() {
    dispatch(delegateStepIdeaActions.postDelegate(id, { observation, usersIds: usersIds.map(({ _id }) => _id) }));
    onClose();
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AvatarIconWithText
            style={{ width: 32, height: 32 }}
            icon={<OutBox />}
            variant="subtitle1"
            text={t('encaminhar_ideia')}
          />
        </Grid>


        <Grid item xs={12}>
          {userList.map(({ id }) => (
            <Grid container spacing={1}>
              <Grid item xs={11} >
                <Autocomplete
                  style={{ marginBottom: 10 }}
                  key={id}
                  options={userFiltered}
                  getOptionLabel={(option) => option.name}
                  value={usersIds.find(item => item.fieldId === id)}
                  onChange={(_e, _items, _options, details) => handleChangeUsersIds({...details.option, fieldId: id})}
                  renderInput={(params) => <TextField {...params} variant="filled" label={t("pessoa_avaliadora")} />}
                />
              </Grid>
              <Grid item xs={1} >
                <IconButton
                  onClick={() => handleRemoveUserField(id)}
                  className={classes.removeButton}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button startIcon={<Add />} variant="outlined" color="primary" onClick={handleAddUserField}>{t('adicionar_pessoa_avaliadora')}</Button>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.about}>
            <Box className={classes.characters}>
              <span>
                {observation.length}/1024 {t('caracteres')}
              </span>
            </Box>
            <TextField
              name="observation"
              label={t('observacoes')}
              multiline
              fullWidth
              minRows={8}
              variant="filled"
              onChange={handleChangeObservation}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent='center' J>
          <FiberManualRecord color='primary' />
          <FiberManualRecord color='primary' />
        </Box>
      </Grid>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent={'space-between'}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleBack('index')}
            startIcon={<ArrowBack />}
          >
            {t('voltar')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
          >
            {t('encaminhar')}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}