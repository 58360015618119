export const generalEvaluationConstants = {
  GET_EVALUATION_GROUP_REQUEST: 'GET_EVALUATION_GROUP_REQUEST',
  GET_EVALUATION_GROUP_SUCCESS: 'GET_EVALUATION_GROUP_SUCCESS',
  GET_EVALUATION_GROUP_FAILURE: 'GET_EVALUATION_GROUP_FAILURE',

  GET_EVALUATION_GROUP_BY_ID_REQUEST: 'GET_EVALUATION_GROUP_BY_ID_REQUEST',
  GET_EVALUATION_GROUP_BY_ID_SUCCESS: 'GET_EVALUATION_GROUP_BY_ID_SUCCESS',
  GET_EVALUATION_GROUP_BY_ID_FAILURE: 'GET_EVALUATION_GROUP_BY_ID_FAILURE',

  GET_ALL_EVALUATIONS_BY_GROUP_REQUEST: 'GET_ALL_EVALUATIONS_BY_GROUP_REQUEST',
  GET_ALL_EVALUATIONS_BY_GROUP_SUCCESS: 'GET_ALL_EVALUATIONS_BY_GROUP_SUCCESS',
  GET_ALL_EVALUATIONS_BY_GROUP_FAILURE: 'GET_ALL_EVALUATIONS_BY_GROUP_FAILURE',

  CREATE_EVALUATION_GROUP_REQUEST: 'CREATE_EVALUATION_GROUP_REQUEST',
  CREATE_EVALUATION_GROUP_SUCCESS: 'CREATE_EVALUATION_GROUP_SUCCESS',
  CREATE_EVALUATION_GROUP_FAILURE: 'CREATE_EVALUATION_GROUP_FAILURE',

  CREATE_GENERAL_EVALUATION_REQUEST: 'CREATE_GENERAL_EVALUATION_REQUEST',
  CREATE_GENERAL_EVALUATION_SUCCESS: 'CREATE_GENERAL_EVALUATION_SUCCESS',
  CREATE_GENERAL_EVALUATION_FAILURE: 'CREATE_GENERAL_EVALUATION_FAILURE',

  UPDATE_EVALUATION_GROUP_REQUEST: 'UPDATE_EVALUATION_GROUP_REQUEST',
  UPDATE_EVALUATION_GROUP_SUCCESS: 'UPDATE_EVALUATION_GROUP_SUCCESS',
  UPDATE_EVALUATION_GROUP_FAILURE: 'UPDATE_EVALUATION_GROUP_FAILURE',

  UPDATE_GENERAL_EVALUATION_REQUEST: 'UPDATE_GENERAL_EVALUATION_REQUEST',
  UPDATE_GENERAL_EVALUATION_SUCCESS: 'UPDATE_GENERAL_EVALUATION_SUCCESS',
  UPDATE_GENERAL_EVALUATION_FAILURE: 'UPDATE_GENERAL_EVALUATION_FAILURE',

  DELETE_GENERAL_EVALUATION_REQUEST: 'DELETE_GENERAL_EVALUATION_REQUEST',
  DELETE_GENERAL_EVALUATION_SUCCESS: 'DELETE_GENERAL_EVALUATION_SUCCESS',
  DELETE_GENERAL_EVALUATION_FAILURE: 'DELETE_GENERAL_EVALUATION_FAILURE',

  DELETE_GENERAL_EVALUATION_GROUP_REQUEST: 'DELETE_GENERAL_EVALUATION_GROUP_REQUEST',
  DELETE_GENERAL_EVALUATION_GROUP_SUCCESS: 'DELETE_GENERAL_EVALUATION_GROUP_SUCCESS',
  DELETE_GENERAL_EVALUATION_GROUP_FAILURE: 'DELETE_GENERAL_EVALUATION_GROUP_FAILURE',

  EVALUATION_ADD_NEW_CRITERIA: 'EVALUATION_ADD_NEW_CRITERIA',
  REMOVE_EVALUATION_CRITERIA: 'REMOVE_EVALUATION_CRITERIA',
  SET_CRITERIA: 'SET_CRITERIA',
  CHANGE_FIELD_TITLE: 'CHANGE_FIELD_TITLE',
  CLEAR_GROUP_AND_EVALUATIONS: 'CLEAR_GROUP_AND_EVALUATIONS',
  CLEAR_CRITERIAS_TO_DELETE: 'CLEAR_CRITERIAS_TO_DELETE',

  UPLOAD_PHOTO_REQUEST: "UPLOAD_PHOTO_REQUEST",
  UPLOAD_PHOTO_SUCCESS: "UPLOAD_PHOTO_SUCCESS",
  UPLOAD_PHOTO_FAILURE: "UPLOAD_PHOTO_FAILURE",

  DELETE_CRITERIA_IMAGE: "DELETE_CRITERIA_IMAGE",
  SET_CRITERIA_ICON: "SET_CRITERIA_ICON",
  CLEAR_GENERAL_EVALUATION: "CLEAR_GENERAL_EVALUATION",
  
  CHANGE_GENERAL_EVALUATION_TABLE_FILTERS: 'CHANGE_GENERAL_EVALUATION_TABLE_FILTERS'
}