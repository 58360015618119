import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useIdeaDetailsController } from '../../../containers/IdeaDetailsPage';
import { ideaConclusionActions, executionActions, fluxOfIdeasActions } from '../../../actions';
import config from '../../../config';

export function useIdeaConclusionController() {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogIsOpen, setDialogConclusion] = useState(false);
  const { 
    startDate,
    conclusionDate,
    financialReturn,
    conclusionDetails,
    costs,
    loadingConclusion
  } = useSelector(state => state.ideaConclusion);
  const { loading } = useSelector(state => state.execution);
  const { loadingUpdate } = useSelector((state) => state.fluxIdeas);
  const regexPattern = new RegExp(',', 'g');
  const regexPointPattern = new RegExp('[.]', 'g');
  const { hasIdeaFlux } = useIdeaDetailsController();

  useEffect(() => {
    if (hasIdeaFlux._id) {
      dispatch(executionActions.getIdea(id));
    }
  }, [dispatch, id, hasIdeaFlux._id]);

  useEffect(() => {
    return () => {
      dispatch(executionActions.clear());
      dispatch(ideaConclusionActions.clear());
    };
  }, []);

  async function sendConclusion() {
    const ideaStepFlux = hasIdeaFlux?.currentStepFlux?.ideaStepFlux;

    let conclusionData = {
      startDate,
      conclusionDate,
      financialReturn: Number(financialReturn.replace(regexPointPattern, '').replace(regexPattern, '.')),
      conclusionDetails,
      costs: costs.map(item => {
        let formattedEstimatedCost = item.estimatedCost.replace(regexPointPattern, '').replace(regexPattern, '.');
        let formattedCost = item.cost.replace(regexPointPattern, '').replace(regexPattern, '.');
        return {
          ...item,
          estimatedCost: Number(formattedEstimatedCost),
          cost: Number(formattedCost)
        }
      })
    }

    await dispatch(ideaConclusionActions.sendConclusionIdea(id, conclusionData, t('a_ideia_foi_concluida_com_sucesso')));

    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      let evaluationData = {
        decision: ideaStepFlux?.decisions.filter(item => item.typeDecision === 'NextStepApproved')[0],
        managerObservation: conclusionDetails,
        personalizedEvaluationsRates: ideaStepFlux?.usePersonalizedEvaluation
      }
  
      await dispatch(fluxOfIdeasActions.updateIdeaStageInFlux(
        id,
        evaluationData,
        t('sua_avaliacao_foi_feita_com_sucesso'),
        ideaStepFlux?.attachmentsRequired
      ));
    }

    closeDialogConclusion();
    history.push('/curation');
  }

  function openModelJustification() {
    setOpenDialog(true);
  }

  function onCloseDialogJustification() {
    setOpenDialog(false);
  }

  function closeDialogConclusion() {
    setDialogConclusion(false);
  }

  function openDialogConclusion() {
    setDialogConclusion(true);
  }
  
  return {
    id,
    loading,
    loadingUpdate,
    openDialog,
    dialogIsOpen,
    loadingConclusion,
    sendConclusion,
    openDialogConclusion,
    closeDialogConclusion,
    openModelJustification,
    onCloseDialogJustification
  }
}