import React, { useEffect, useState } from 'react'
import {
  Grid,
  TextField,
  Checkbox,
  Box,
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxOutlineBlank, CheckBox as CheckboxIcon } from '@material-ui/icons';
import { businessUnitPropsAction } from "./../../actions"
import { useTranslation } from 'react-i18next';

const filterOptions = createFilterOptions({
  limit: 100,
})

export const BusinessUnitPropsCheckboxList = ({ 
  businessUnitField = true, 
  localField = true,
  occupationAreaField = true 
}) => {
  const dispatch = useDispatch();
  const [ fieldSize, setFieldSize ] = useState(3)
  const { 
    local, 
    occupationArea, 
    businessUnit,
    firstField,
  } = useSelector(state => state.businessUnitProps)
  const { imagineLocals } = useSelector(state => state.domains)
  const { t } = useTranslation();

  useEffect(() => {
    if (imagineLocals) {
      dispatch(businessUnitPropsAction.loadBusinessUnitProps({
        imagineLocals,
        businessUnitField,
        localField,
        occupationAreaField
      }))
    }
  }, [imagineLocals])

  useEffect(() => {
    let acumulador = 0;
    localField && acumulador++;
    occupationAreaField && acumulador++;
    businessUnitField && acumulador++;
    setFieldSize(12/acumulador)
  }, [])

  useEffect(() => {
    return () => {
      dispatch(businessUnitPropsAction.clearAll())
    }
  }, [])

  function handleChangeField(businessUnitProp, data) {
    if (data) {
      dispatch(businessUnitPropsAction.changeBusinessUnitPropField(businessUnitProp, data.option, imagineLocals))
    } else {
      //isso aqui so existe por causa do botao default do autocomplete de limpar tudo
      dispatch(businessUnitPropsAction.clearBusinessUnitPropField(businessUnitProp))
    }
  }

  return (
    <Grid container spacing={2}>
      { businessUnitField && (
        <Grid item xs={12} md={fieldSize}>
          <Autocomplete
            multiple
            filterOptions={filterOptions}
            disableCloseOnSelect
            id="checkboxes-businessUnit"
            options={businessUnit}
            loading={businessUnit.length === 0}
            value={businessUnit.filter(item => item.checked)}
            onChange={ (e, items, options, details) => handleChangeField("businessUnit", details) }
            getOptionLabel={(input) => input.label}
            renderInput={(params) => (
              <TextField {...params} label={t('unidade_de_negocio')} variant="filled" />
            )}
            renderTags={(option, state) => {
              return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
            }}
            renderOption={(value) => {
              return (
                <li>
                  <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckboxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={value.checked}
                    />
                  {value.label}
                </li>
            )}}
          />
        </Grid>
      )}


      { localField && (
        <Grid item xs={12} md={fieldSize}>
          <Autocomplete
            multiple
            filterOptions={filterOptions}
            disableCloseOnSelect
            disabled={firstField !== "local" && local.length === 0}
            id="checkboxes-local"
            options={local}
            loading={local.length === 0}
            getOptionLabel={(input) => input.label}
            value={local.filter(item => item.checked)}
            onChange={ (e, items, options, details) => handleChangeField("local", details) }
            renderInput={(params) => (
              <TextField {...params} label={t('local')} variant="filled" />
            )}
            renderTags={(option, state) => {
              return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
            }}
            renderOption={(value) => (
              <li>
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckboxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={value.checked}
                />
                {value.label}
              </li>
            )}
          />
        </Grid>
      )}

      {occupationAreaField && (
        <Grid item xs={12} md={fieldSize}>
          <Autocomplete 
            multiple
            filterOptions={filterOptions}
            disableCloseOnSelect
            id="checkboxes-area"
            disabled={firstField !== "occupationArea" && occupationArea.length === 0}
            options={occupationArea}
            loading={occupationArea.length === 0}
            value={occupationArea.filter(item => item.checked)}
            onChange={ (e, items, options, details) => handleChangeField("occupationArea", details) }
            getOptionLabel={(input) => input.label}
            renderInput={(params) => (
              <TextField {...params} label={t('area')} variant="filled" />
            )}
            renderTags={(option, state) => {
              return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
            }}
            renderOption={(value) => {
              return (
                <Box>
                  <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckboxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={value.checked}
                    />
                  {value.label}
                </Box>
            )}}
          />
        </Grid>
      )}

    </Grid>
  )
}
