import { authenticationConstanst } from '../constants';

let token = JSON.parse(localStorage.getItem('token'));
const initialState = token ? { loading: false, loggedIn: true, token } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authenticationConstanst.LOGIN_REQUEST:
      return {
        loading: true,
        loggingIn: true,
      };

    case authenticationConstanst.LOGIN_SUCCESS:
      return {
        loading: false,
        loggedIn: true,
        token: action.token,
      };

    case authenticationConstanst.LOGIN_FAILURE:
      return {
        loading: false,
        type: 'error',
      };

    case authenticationConstanst.LOGIN_OFIICE_REQUEST:
      return {
        loggingIn: true,
      };

    case authenticationConstanst.LOGIN_OFFICE_SUCCESS:
      return { loggedIn: true, token: action.payload.token, loggingIn: false };

    case authenticationConstanst.LOGIN_OFFICE_FAILURE:
      return {
        loggingIn: false,
      };

    case authenticationConstanst.REGISTER_OFFICE_SUCCESS:
      return state;

    case authenticationConstanst.REGISTER_OFFICE_FAILURE:
      return state;

    case authenticationConstanst.LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return {};

    default:
      return state;
  }
}