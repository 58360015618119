import {
  styled,
  makeStyles,
  Box,
  Card as MuiCard,
  Typography,
} from '@material-ui/core';

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.grey.main}`,
  padding: theme.spacing(4),
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('xs')]: {
    display: 'inline',
    flexDirection: 'column'
  }
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.neutrals.low.light,
  fontWeight: 'bold',
}));

export const Legend = styled(Box)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.neutrals.low.light,
  fontWeight: 600,
}));

export const Circle = styled(Box)({
  width: 12,
  height: 12,
  borderRadius: 50,
  marginRight: 8
});

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems : 'center',
  gap: 10,

  [theme.breakpoints.down('xs')]: {
    justifyContent: 'center',
    marginTop: 24
  }
}));

export const useStyles = makeStyles((theme) => ({
  buttonSelected: {
    borderRadius: 32,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    height: 32,
    fontSize: 14,
    fontWeight: 500
  },
  button: {
    borderRadius: 32,
    backgroundColor: 'transparent',
    border: `.5px solid #CED4DA`,
    height: 32,
    fontSize: 14,
    fontWeight: 500
  }
}));