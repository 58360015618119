import React from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import { tableCss } from './TableCss';
import { dateFormat, truncate, history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TableMenu } from './TableMenu';
import makeStyles from "@material-ui/styles/makeStyles"
import { CustomTooltip, StepIdea } from '../Shared';

const useStyles = makeStyles((theme) => ({
  relative: {
    position: "relative"
  },
}))

function CurationTable({ handleChangePage, handleChangeRowsPerPage }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const { ideas, paging } = useSelector((state) => state.curation);
  const { typeProfile: { type: userType }} = useSelector(state => state.user)
  const linhasTable = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(linhasTable);
  const navigate = (to) => window.open(to, "_blank");

  const columns = [
    { id: 'code', label: t('codigo') },
    { id: 'date', label: t('data') },
    { id: 'title', label: t('titulo_da_ideia') },
    { id: "author", label: t("autor")},
    { id: 'challenge', label: t('desafio') },
    { id: 'unitLocal', label: t('unidade_de_negocio') },
    { id: 'status', label: t('status') },
    { id: 'actions', label: '' },
  ];

  function handleEdit(id) {
    navigate(`/idea-edit/${id}`);
  }

  function handleView(idea) {
    switch (idea.statusIdea?.code) {
      case 'AWAITING_QUALIFICATION':
        navigate(`/idea/${idea._id}/general`);
        return;
      case 'EXECUTION_QUEUE':
        navigate(`/idea/${idea._id}/execution`);
        return;
      case 'EXECUTING':
        navigate(`/idea/${idea._id}/conclusion`);
        return;
      default:
        navigate(`/idea/${idea._id}/general`);
    }
  }

  return (
    <Paper className={tableClasses.paperRoot}>
      <TableContainer className={classes.relative}>
        <Table
          stickyHeader
          aria-label="tabelas de ideias"
          classes={{ root: tableClasses.table }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  classes={{ root: tableClasses.tableCellHeader }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ideas && ideas.map((idea) => (
              <TableRow
                hover
                key={idea._id}
                classes={{ root: tableClasses.tableRow }}
                style={{height: 60}}
              >
                <TableCell
                  classes={{ root: tableClasses.tableCellRadiusLeft }}
                >
                  {idea.code}
                </TableCell>
                <TableCell classes={{ root: tableClasses.tableCell }}>
                  {dateFormat(idea.createdAt, language)}
                </TableCell>
                <TableCell
                  className={tableClasses.oneLine}
                  classes={{ root: tableClasses.tableCell }}
                >
                  <CustomTooltip 
                    title={idea.name} 
                    aria-label={idea.name} 
                    placement="bottom-start"
                  >
                    <Typography 
                      variant="subtitle2" 
                      className={tableClasses.link} 
                      onClick={() => handleView(idea)}
                    >
                      {truncate(idea.name, 30)}
                    </Typography>
                  </CustomTooltip>
                </TableCell>
                <TableCell
                  className={tableClasses.oneLine}
                  classes={{ root: tableClasses.tableCell }}
                >
                  {truncate(idea.userName, 30)}
                </TableCell>
                <TableCell
                  className={tableClasses.oneLine}
                  classes={{ root: tableClasses.tableCell }}
                >
                  {idea.challenge && idea.challenge.name}
                </TableCell>
                <TableCell
                  className={tableClasses.oneLine}
                  classes={{ root: tableClasses.tableCell }}
                  style={{minWidth:200}}
                >
                  {
                    idea.businessUnitLocal && idea.businessUnitLocal.length > 0 &&
                    truncate(idea.businessUnitLocal[0].local.name, 30)}
                </TableCell>
                <TableCell 
                  className={tableClasses.tableCellRadiusRight}
                  classes={{ root: tableClasses.tableCell }}
                >
                  <StepIdea step={idea.currentStepFlux?.ideaStepFlux} />
                </TableCell>
                <TableMenu>
                  <List>
                    <ListItem onClick={() => handleView(idea)} button>
                      <ListItemIcon>
                        <ViewIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={<Typography>
                          {t('visualizar')}
                        </Typography>
                        }
                      />
                    </ListItem>
                    { userType === "admin" && (
                      <ListItem onClick={() => handleEdit(idea._id)} button>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={<Typography>
                            {t('editar')}
                          </Typography>
                          }
                        />
                      </ListItem>
                    )}
                  </List>
                </TableMenu>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        classes={{
          input: tableClasses.paginationInput,
          spacer: tableClasses.paginationSpacer,
          caption: tableClasses.paginationCaption,
          toolbar: tableClasses.paginationToolbar,
        }}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        count={paging.total}
        rowsPerPage={paging.limit}
        page={paging.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('exibindo')}
      />
    </Paper>
  );
}

export { CurationTable };
