export const optionConstants = {
    IDEA: 'idea',
    GENERAL: 'general',
    CONCLUSION: "conclusion",
    RESULTS: "RESULTS",
    ENGAGEMENT: 'engagement',
    QUALIFICATION: 'qualification',
    PROFILE: 'edit-profile',
    NOTIFICATIONS: 'notifications',
    TUTORIAIS: 'tutoriais',
    ADMINISTRADORES: 'administradores',
    PRIMEIROACESSO: 'primeiro-acesso',
    FAQ: 'faq',
    ATRIBUIR_FUNCAO_AO_USUARIO: 'atribuir-funcao',
    EDITAR_DADOS_DE_USUARIO: 'editar-dados',
    GERENCIAR_PONTOS: 'gerenciar-pontos'

};
  